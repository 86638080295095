import { StarIcon } from '@chakra-ui/icons';
import SadIcon from '../../../../images/sadicon2.png';
import {
  Avatar,
  Box,
  Button,
  chakra,
  ButtonGroup,
  Flex,
  HStack,
  VStack,
  WrapItem,
  LinkBox,
  Heading,
  Text,
  Td,
  Badge,
  Table,
  Alert,
  AlertIcon,
  Tr,
  TableContainer,
  useDisclosure,
  Center,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Stack,
  useToast,
} from '@chakra-ui/react';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../../Loading';
import { AccountContext } from '../../../AccountContext';
import BottomNavBar from '../../../BottomNavBar';
import ChildHeader from '../ChildHeader';
import PopupDependenteSemCpf from './PopupDependenteSemCpf';
import SelectField from 'components/SelectField';
import { Formik } from 'formik';
import '../../css/lista-datas.css';
import NoUserHeader from 'components/AreaLivre/NoUserHeader';
import DrawerCompraPlano from '../DrawerCompraPlano';
import DrawerServico from '../DrawerServico';
import _ from 'lodash';
import LoadingGenerico from '../Telemedicina/Especialista/LoadingGenerico';
const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Agendamento() {
  const navigate = useNavigate();
  const { user, setPage, setUser, toastInadimplente } = useContext(AccountContext);
  const [dependentes, setDependentes] = useState([]);
  const arrayNomeDependentes = dependentes ? dependentes.map((dependente) => dependente.nome) : [];

  //dados do medico
  const { state } = useLocation();
  const { medico, startNum, st, defaultDateTime, procedimento, price, procedimentoId, procedimentoParticular, valorParticular } = state;
  const newState = { ...state };
  const [updatedState, setUpdatedState] = useState(newState);

  // return;
  const arrName = medico.dados.nome.split(' ');
  const [pag, setPag] = useState(startNum);
  const [pac, setPac] = useState(st);
  const [escolha, setEscolha] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [agendas, setAgendas] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const avaliacao = Math.round(medico.dados.avaliacao_media);
  const disclosure = useDisclosure();
  const scheduleDrawer = useDisclosure();
  const [isLoading, setIsLoading] = useState(true);
  const [priceData, setPriceData] = useState(procedimento);
  const [checkingAvailability, setCheckingAvailability] = useState(false);
  const loading = useDisclosure();
  const [reload, setReload] = useState(false);
  const toast = useToast();
  const [pickWarning, setPickWarning] = useState(false);
  let faixa_inicial = 0;
  let faixa_final = 0;

  const faixa_etaria = agendas?.map((faixas, index) => {
    if (faixas.faixa_etaria !== 0 || faixas.faixa_etaria_final !== 0) {
      faixa_inicial = faixas.faixa_etaria;
      faixa_final = faixas.faixa_etaria_final;
    }
  });

  let titulo = '';
  if (medico.dados.sexo === 'M') {
    titulo = 'Dr. ';
  } else if (medico.dados.sexo === 'F') {
    titulo = 'Dra. ';
  }

  setPage('Agendamento');

  let assinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) assinante = true;

  const [idClientClinica, setIdClientCinica] = useState(0);
  const getClinicasId = async (cpf) => {
    const url = ENDPOINT + '/clients/getClientByCpfClinica/' + cpf;
    const response = await fetch(url, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      method: 'GET',
    });
    const postsData = await response.json();
    if (postsData.length > 0) {
      setIdClientCinica(postsData[0].paciente_id);
      setReload(true);
    } else {
      setIdClientCinica(null);
    }
  };

  useEffect(() => {
    getClinicasId(user.username);
  }, [user.username]);

  const fetchPosts = async () => {
    const url = ENDPOINT + '/schedule/getAvailability/' + medico.dados.medico + '/' + procedimentoId + '/' + id_cliente;
    const response = await fetch(url, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      method: 'GET',
    });
    const postsData = await response.json();
    setAgendas(postsData);
    setPickWarning(false);
    setIsLoading(false);
  };

  const id_cliente = user.loggedIn ? (idClientClinica !== null ? idClientClinica : user.id_cliente_deovita) : 0;
  let pacienteId = id_cliente;
  const handlePick = async (agendaId, medico, agenda, filteredAgenda, filteredAgendasOrdemChegada, index) => {
    try {
      loading.onOpen();
      if (!idClientClinica) {
        const postsData = await fetch(`${ENDPOINT}/clients/post_client_clinicas`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ cpf: user.username }),
        });

        const response = await postsData.json();
        console.log('response ', response);

        if (response.paciente_id === 0) {
          toast({
            title: 'Importante',
            description: 'Não foi possível realizar a operação. Entre em contato com o suporte',
            status: 'warning',
            duration: 4000,
            isClosable: true,
            position: 'top',
            colorScheme: 'red',
            containerStyle: {
              paddingTop: '50px',
            },
          });
          return;
        }
        pacienteId = response.paciente_id;
      }

      const url = ENDPOINT + '/schedule/checkAvailability/' + agendaId + '/' + pacienteId;
      const response = await fetch(url, {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        method: 'GET',
      });
      const postsData = await response.json().then(({ available }) => {
        if (available === true) {
          document.getElementById('agenda' + index).scrollIntoView({ behavior: 'smooth' });
          document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
            item.style.border = 'none';
          });
          document.getElementById('agenda' + index).scrollIntoView({ inline: 'center', behavior: 'smooth' });
          document.getElementById('agenda' + index).style.border = '1px solid orange';

          setEscolha({
            medicoNome: medico.dados.nome,
            tipo: medico.dados.tipo,
            especialidade: medico.dados.especialidade,
            valor:
              (user.id_contrato > 0 || (user.loggedIn === true && user.titular === false)) &&
              (!user.qntdeParcelas?.countParcelas || user.qntdeParcelas?.countParcelas === 0 || user.qntdeParcelas?.countParcelas === '0')
                ? updatedState.price
                : updatedState.valorParticular,
            agenda: filteredAgenda,
            paciente: dependentes[pac],
            clinica: medico.dados.empresa,
            logradouro: medico.dados.logradouro,
            numero: medico.dados.numero,
            bairro: medico.dados.bairro,
            municipio: medico.dados.municipio,
            medico: medico,
            procedimento: procedimento,
            procedimentoId:
              (user.id_contrato > 0 || (user.loggedIn === true && user.titular === false)) &&
              (!user.qntdeParcelas?.countParcelas || user.qntdeParcelas?.countParcelas === 0 || user.qntdeParcelas?.countParcelas === '0')
                ? procedimentoId
                : procedimentoParticular,
          });
          if (escolha) {
            setUser((prevState) => ({ ...prevState, pedido_pendente: { ...escolha, agenda: escolha.agenda } }));
          }
          //lockAgenda(filteredAgenda.AVAILABILITY_LID);
          //socket.emit('unlockSchedule', '');
          //socket.emit('lockSchedule', filteredAgenda.AVAILABILITY_LID);
          loading.onClose();
          dependentes[pac].cpf && setDisabled(true);
        } else {
          console.log(available);
          loading.onClose();
          setPickWarning(true);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);

    const fetchDependentes = async () => {
      const url2 = ENDPOINT + '/client/dependents2';
      const titularId = {
        id_contrato: user.id_contrato,
        idade_inicial: faixa_inicial ? faixa_inicial : 0,
        idade_final: faixa_final ? faixa_final : 0,
      };
      const response = await fetch(url2, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(titularId),
      });
      const resp = await response.json();
      if (resp.data[0] !== null) {
        setDependentes(resp.data);
      } else {
        setDependentes([{ nome: user.name, paciente_id: user.id_cliente_deovita, ...user }]);
      }
    };
    fetchDependentes();
    fetchPosts();
    toastInadimplente(user.id_forma_pagamento, user.qntdeParcelas);
  }, [reload]);

  let uniqueDates = [];
  const useAgenda = agendas.map((agenda, index) => {
    if (!uniqueDates.includes(agenda.AVA_DATE)) {
      uniqueDates.push(agenda.AVA_DATE);
    }
  });

  useEffect(() => {
    const agendaEscolhida = agendas.find((i) => uniqueDates[pag] === i.AVA_DATE);
    if (agendaEscolhida) {
      newState.price = agendaEscolhida.PRICE;
      newState.valorParticular = agendaEscolhida.valor_particular;
      setUpdatedState(newState);
    }
  }, [agendas]);

  function escolhaData(uniqueDate) {
    const agendaEscolhida = agendas.find((i) => uniqueDate === i.AVA_DATE);
    if (agendaEscolhida) {
      newState.price = agendaEscolhida.PRICE;
      newState.valorParticular = agendaEscolhida.valor_particular;
      setUpdatedState(newState);
    }
  }

  const useUniqueDates = uniqueDates.map((uniqueDate, index) => {
    const options = { month: 'short', day: 'numeric' };
    const event = new Date(uniqueDate.split('/').reverse().join('-') + 'T08:00:00');
    const dataIcon = event.toLocaleDateString('pt-br', options).replace(' de ', ' ').replace('.', '').split(' ');
    return (
      <>
        <Flex>
          <Box backgroundColor="rgba(90, 178, 173, 0.3)" borderRadius="5px" margin="5px" color="rgba(62, 100, 255, 1)" padding="0 13px">
            <Text fontWeight="bold" fontSize="xl">
              {dataIcon[0]}
            </Text>
            <Text fontWeight="bold" fontSize="sm" mt="-1">
              {dataIcon[1].toUpperCase()}
            </Text>
          </Box>
          <Text margin="5% 0" fontSize="xs" align="left">
            <chakra.p fontWeight="bold">{medico.dados.tipo ? medico.dados.tipo.toUpperCase() : ''}</chakra.p>
            {event.toLocaleString('pt-br', { weekday: 'long' })}
          </Text>
        </Flex>
      </>
    );
  });

  const filteredAgendasOrdemChegada = agendas.filter((i) => uniqueDates[pag].includes(i.AVA_DATE) && i.ordem_chegada === true);
  let horario_chegada = filteredAgendasOrdemChegada.filter(
    (item, index, self) => index === self.findIndex((i) => i.AVA_START_TIME === item.AVA_START_TIME)
  );

  const filteredAgendas = agendas.filter((i) => uniqueDates[pag].includes(i.AVA_DATE) && i.ordem_chegada === false);
  const mapDates = uniqueDates.map((uniqueDate, index) => {
    const options = { month: 'short', day: 'numeric' };
    const event = new Date(uniqueDate.split('/').reverse().join('-') + 'T08:00:00');
    const dataIcon = event.toLocaleDateString('pt-br', options).replace(' de ', ' ').replace('.', '').split(' ');

    return (
      <>
        <Td
          id={`td${index}`}
          borderRadius="15px"
          p="0.5em"
          fontSize="xl"
          textAlign="center"
          class="margin-td"
          onClick={() => {
            document.getElementById('td' + index).scrollIntoView({ behavior: 'smooth' });
            document.querySelectorAll(`[class="chakra-stack css-1px2q30"]`).forEach((item) => {
              item.style.border = 'none';
            });
            document.querySelectorAll(`[class="chakra-badge css-raqg5x"]`).forEach((item) => {
              item.style.border = 'none';
            });
            document.getElementById('date' + index).style.border = '1px solid orange';
            setPag(index);
            setDisabled(false);
            escolhaData(uniqueDate);
          }}
        >
          <HStack
            id={`date${index}`}
            backgroundColor="rgba(90, 178, 173, 0.25)"
            borderRadius="15px"
            style={pag === index ? { border: '1px solid orange' } : {}}
          >
            <Text width="60%">
              <Flex ml="1em">
                <Box backgroundColor="rgba(90, 178, 173, 0.3)" borderRadius="5px" margin="1em" color="rgba(62, 100, 255, 1)" padding="0 1em">
                  <Text fontWeight="bold" fontSize="xl">
                    {dataIcon[0]}
                  </Text>
                  <Text fontWeight="bold" fontSize="sm" mt="-1">
                    {dataIcon[1].toUpperCase()}
                  </Text>
                </Box>
                <Text margin="11% 0" fontSize="xs" align="left">
                  <chakra.p fontWeight="bold">{medico.dados.tipo ? medico.dados.tipo.toUpperCase() : ''}</chakra.p>
                  {event.toLocaleString('pt-br', { weekday: 'long' })}
                </Text>
              </Flex>
            </Text>
          </HStack>
        </Td>
      </>
    );
  });

  let agenda = '';
  function filterAllAgendas(array, ordem) {
    if (ordem) {
      agenda = filteredAgendasOrdemChegada.filter((item, index, self) => index === self.findIndex((i) => i.AVA_START_TIME === item.AVA_START_TIME));
      // setPriceData(1);
    } else {
      //agenda = agendas.filter((i) => uniqueDates[pag].includes(i.AVA_DATE) && i.ordem_chegada === false);
      const uniqueAvailabilityLidSet = new Set();

      agenda = agendas.filter((i) => {
        const isUnique = !uniqueAvailabilityLidSet.has(i.AVAILABILITY_LID);
        if (isUnique && uniqueDates[pag].includes(i.AVA_DATE) && i.ordem_chegada === false) {
          uniqueAvailabilityLidSet.add(i.AVAILABILITY_LID);
          return true;
        }
        return false;
      });
    }
    return agenda;
  }

  if (filteredAgendasOrdemChegada.length > 0) {
    filterAllAgendas(horario_chegada, true);
  } else {
    filterAllAgendas(filteredAgendas, false);
  }

  const useFilteredAgendas = agenda.map((filteredAgenda, index) => {
    if (!escolha) {
      var dateTime = `${filteredAgenda.AVA_DATE} ${filteredAgenda.AVA_START_TIME}`;
      if (dateTime === defaultDateTime) {
        setEscolha({
          medicoNome: medico.dados.nome,
          tipo: medico.dados.tipo,
          especialidade: medico.dados.especialidade,
          valor:
            (user.id_contrato > 0 || (user.loggedIn === true && user.titular === false)) &&
            (!user.qntdeParcelas?.countParcelas || user.qntdeParcelas?.countParcelas === 0 || user.qntdeParcelas?.countParcelas === '0')
              ? updatedState.price
              : updatedState.valorParticular,
          agenda: filteredAgenda,
          paciente: dependentes[pac],
          clinica: medico.dados.empresa,
          logradouro: medico.dados.logradouro,
          numero: medico.dados.numero,
          bairro: medico.dados.bairro,
          municipio: medico.dados.municipio,
          medico: medico,
          procedimento: procedimento,
          procedimentoId:
            (user.id_contrato > 0 || (user.loggedIn === true && user.titular === false)) &&
            (!user.qntdeParcelas?.countParcelas || user.qntdeParcelas?.countParcelas === 0 || user.qntdeParcelas?.countParcelas === '0')
              ? procedimentoId
              : procedimentoParticular,
        });
        //lockAgenda(filteredAgenda.AVAILABILITY_LID);
        //socket.emit('lockSchedule', filteredAgenda.AVAILABILITY_LID);
        setDisabled(true);
      }
    }

    return (
      <>
        <Td>
          <Badge
            id={'agenda' + index}
            borderRadius="0.5em"
            margin="0 0.5em"
            padding="0.5em 1.4em"
            size="lg"
            fontSize={filteredAgendasOrdemChegada.length > 0 ? 'sm' : 'md'}
            style={`${filteredAgenda.AVA_DATE} ${filteredAgenda.AVA_START_TIME}` === defaultDateTime ? { border: '1px solid orange' } : {}}
            backgroundColor="rgba(90, 178, 173, 0.3)"
            color="rgba(62, 100, 255, 1)"
            onClick={() => {
              handlePick(filteredAgenda.AVAILABILITY_LID, medico, agenda, filteredAgenda, filteredAgendasOrdemChegada, index);
              /**/
            }}
          >
            {filteredAgendasOrdemChegada.length > 0 ? `A partir de ${filteredAgenda.AVA_START_TIME}` : filteredAgenda.AVA_START_TIME}
          </Badge>
        </Td>
      </>
    );
  });

  const confirmEstimate = (id) => {
    if (id > 0) {
      const postData = fetch(`${ENDPOINT}/cart/estimate/${id}/confirm`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 200) {
          let newCartItems = _.filter(user.solicitacoes, (item) => !_.includes([id], item.ambulatorio_orcamento_id));
          if (newCartItems.length === 0) {
            setUser((prevState) => ({ ...prevState, solicitacoes: {} }));
          } else {
            setUser((prevState) => ({ ...prevState, solicitacoes: { ...newCartItems } }));
          }
        }
      });
    }
  };

  let data_parcela = '';
  if (user.qntdeParcelas?.countParcelas > 0) {
    data_parcela = user?.qntdeParcelas?.datasParcelas !== null ? new Date(user?.qntdeParcelas?.datasParcelas[0]) : null;
    if (data_parcela !== null) {
      const dia = user?.qntdeParcelas?.forma_pagamento === 13 ? 60 : 8;
      data_parcela.setDate(data_parcela?.getDate() + dia);
    }
  }
  const dataAtual = new Date();

  return dependentes.length > 0 && agendas.length > 0 && isLoading === false ? (
    <>
      {user.loggedIn === true ? <ChildHeader /> : <NoUserHeader />}
      {user.loggedIn === true ? <PopupDependenteSemCpf disclosure={disclosure} datas={dependentes[pac]} /> : null}
      <Modal isOpen={pickWarning} isCentered>
        <ModalOverlay />
        <ModalContent height="250px" width="300px" border-radius="1em">
          <ModalHeader></ModalHeader>

          <ModalBody>
            <VStack>
              <Heading size="md" textAlign="center" pl="5" pr="5" mt="15">
                Opa! O horário escolhido não está mais disponível.
              </Heading>
              <Stack direction="row" ml="-4" pt="1em" spacing={5} align="center">
                <Button
                  id="id_agd_continuar"
                  backgroundColor={user.id_contrato ? '#EB8B2A' : '#529C94'}
                  color="#FFF"
                  variant="solid"
                  size="lg"
                  onClick={() => {
                    setReload(!reload);
                  }}
                >
                  continuar
                </Button>
              </Stack>
            </VStack>
          </ModalBody>

          <VStack ml="4"></VStack>
        </ModalContent>
      </Modal>
      <DrawerCompraPlano disclosure={disclosure} />
      <DrawerServico disclosure={scheduleDrawer} />
      <LoadingGenerico disclosure={loading} />
      <Formik initialValues={{ empresa: 0, dependente: 0 }} validateOnChange={false} validateOnBlur={false}>
        <VStack
          w={{
            base: '90%',
            md: '500px',
          }}
          m="auto"
          justify="center"
        >
          <Flex minW="320px" maxW="320px" mx="auto" bg="" overflow="hidden">
            <Box w={1 / 3} bgSize="cover" align="center">
              <WrapItem ml="5px" mt="0.8em">
                <Avatar
                  id={medico.dados.medico_id}
                  size="xl"
                  name={medico.dados.nome}
                  src={process.env.REACT_APP_URL_PROFILEPICS_OPERADOR + '/' + medico.dados.medico + '/foto_profissional.png'}
                />{' '}
              </WrapItem>
              <chakra.a
                color="rgba(14, 170, 139, 1)"
                fontWeight="bold"
                fontSize="sm"
                onClick={() => {
                  navigate('/empresa/medico/curriculo', {
                    state: {
                      medico,
                    },
                  });
                }}
              >
                ver curriculo
              </chakra.a>
            </Box>
            <Box w={2 / 3} p={{ base: 4, md: 4 }}>
              <chakra.h2 fontSize="xl" fontWeight="bold" color="">
                {titulo}
                {''}
                {arrName[0]} {arrName[1]}
              </chakra.h2>
              <chakra.p mt={-1} fontSize="md" color="">
                {medico.dados.especialidade}
              </chakra.p>
              <HStack>
                <chakra.p fontSize="sm">{medico.dados.conselho}</chakra.p>
              </HStack>
              {avaliacao > 0 ? (
                <>
                  <HStack spacing={1} display="flex" alignItems="center" fontSize="sm" pt={0.5}>
                    {avaliacao >= 1 ? <StarIcon color="orange" /> : <StarIcon color="gray.500" />}
                    {avaliacao >= 2 ? <StarIcon color="orange" /> : <StarIcon color="gray.500" />}
                    {avaliacao >= 3 ? <StarIcon color="orange" /> : <StarIcon color="gray.500" />}
                    {avaliacao >= 4 ? <StarIcon color="orange" /> : <StarIcon color="gray.500" />}
                    {avaliacao >= 5 ? <StarIcon color="orange" /> : <StarIcon color="gray.500" />}
                    <chakra.span fontSize="xs"></chakra.span>
                  </HStack>
                </>
              ) : (
                <></>
              )}
            </Box>
          </Flex>

          <LinkBox as="card" pl="3" pr="3" borderRadius="15px" fontSize="xs" width="100%">
            <SelectField
              name="empresa"
              placeholder="Empresa"
              colorScheme="teal"
              border="green"
              color="black"
              borderRadius="15px"
              borderWidth="2px"
              variant="ghost"
              fontWeight="bold"
              w="100%"
              options={[medico.dados.empresa]}
              onChange={() => {}}
            />
            <Text fontSize="xs" padding="0 1em">
              {medico.dados.agenda_especialidade === true ? (
                <>
                  {' '}
                  <chakra.p color="gray.500">
                    Atendimento a ser realizado na modalidade a distância. Certifique-se de entrar no app 15 minutos antes da sua consulta medica
                    iniciar.
                  </chakra.p>
                </>
              ) : (
                <chakra.p color="gray.500">
                  {' '}
                  {medico.dados.logradouro}, {medico.dados.numero} - {medico.dados.bairro} - {medico.dados.municipio}
                </chakra.p>
              )}
            </Text>
          </LinkBox>

          {(user.id_contrato > 0 || user.titular === false) &&
          (!user.qntdeParcelas?.countParcelas || user.qntdeParcelas?.countParcelas === 0 || user.qntdeParcelas?.countParcelas === '0') ? (
            <>
              <chakra.h1 fontWeight="bold">Procedimento</chakra.h1>
              <LinkBox as="card" borderRadius="15px" fontSize="xs" width="100%">
                <Center>
                  <Box w="60%">
                    <Text margin="5% 0" fontSize="xs" w="60%">
                      {procedimento ? procedimento + ' ' : ''}
                    </Text>
                    <Badge align="center" bgColor="#FF9A00" borderRadius="15px" w="50%">
                      Assinante
                    </Badge>
                  </Box>
                  <Box>
                    <Text mt="-1em" fontSize="md" fontWeight="bold">
                      {updatedState.price ? 'R$ ' + updatedState.price : ''}
                    </Text>
                  </Box>
                </Center>
              </LinkBox>
            </>
          ) : (
            <>
              <chakra.h1 fontWeight="bold">Selecione uma opção:</chakra.h1>
              <LinkBox
                as="card"
                p="3"
                borderWidth="2px"
                borderRadius="15px"
                fontSize="xs"
                width="100%"
                onClick={() => {
                  console.log(user.qntdeParcelas?.countParcelas?.countParcelas);
                  if (user.qntdeParcelas?.countParcelas && user.qntdeParcelas?.countParcelas > 0 && dataAtual >= data_parcela) {
                    toastInadimplente(user.id_forma_pagamento, user.qntdeParcelas);
                    return;
                  } else {
                    scheduleDrawer.onOpen();
                  }
                }}
              >
                <Center>
                  <Box w="60%">
                    <Text margin="5% 0" fontSize="xs" w="60%">
                      {procedimento ? procedimento + ' ' : ''}
                    </Text>
                    <Badge align="center" bgColor="#FF9A00" borderRadius="15px" w="50%">
                      Assinante
                    </Badge>
                  </Box>
                  <Box>
                    <Text mt="-1em" fontSize="md" fontWeight="bold">
                      {updatedState.price
                        ? parseFloat(updatedState.price).toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })
                        : ''}
                    </Text>
                  </Box>
                </Center>
              </LinkBox>
              {updatedState.valorParticular && (
                <LinkBox as="card" p="3" borderWidth="2px" borderColor="green" borderRadius="15px" fontSize="xs" width="100%">
                  <Center>
                    <Box w="60%">
                      <Text margin="5% 0" fontSize="xs" w="60%">
                        {procedimento ? procedimento + ' ' : ''}
                      </Text>
                      <Badge align="center" bgColor="d9d9d9" borderRadius="15px" w="60%">
                        Particular
                      </Badge>
                    </Box>
                    <Box>
                      <Text mt="-1em" fontSize="md" fontWeight="bold">
                        {updatedState.valorParticular
                          ? parseFloat(updatedState.valorParticular).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                          : ''}
                      </Text>
                    </Box>
                  </Center>
                </LinkBox>
              )}
            </>
          )}
          {user.titular === true && user.id_contrato > 0 && (
            <>
              <chakra.h1 fontWeight="bold">Paciente</chakra.h1>
              <LinkBox id="" as="card" p="3" borderWidth="2px" borderColor="green" borderRadius="15px" fontSize="xs" width="100%">
                <Heading size="sm" my="2">
                  <SelectField
                    id="id_agd_paciente"
                    name="dependente"
                    colorScheme="teal"
                    border="green"
                    color="black"
                    borderRadius="15px"
                    borderWidth="2px"
                    variant="ghost"
                    fontWeight="bold"
                    w="100%"
                    onChange={(e) => {
                      if (dependentes[e].cpf) {
                        getClinicasId(dependentes[e].cpf);
                        setPac(e);
                        setDisabled(true);
                      } else {
                        setPac(e);
                        setDisabled(false);
                        disclosure.onOpen();
                      }
                    }}
                    options={arrayNomeDependentes}
                  />
                </Heading>
                <Text fontSize="xs" padding="0 1em">
                  <chakra.p color="gray.500"></chakra.p>
                </Text>
              </LinkBox>
              {faixa_inicial > 0 || faixa_inicial != null || faixa_inicial != undefined ? (
                <Alert status="warning" borderRadius="15px">
                  <AlertIcon />
                  Esse profissional atende apenas pacientes da seguinte faixa etária:{' '}
                  {faixa_final > 0 ? `entre ${faixa_inicial} e ${faixa_final} anos` : `a partir de ${faixa_inicial} anos`}
                </Alert>
              ) : (
                <></>
              )}
            </>
          )}
          <chakra.h1 fontWeight="bold">Selecione dia e horário</chakra.h1>
          {user.loggedIn === true &&
          (user.id_contrato === 0 || !user.id_contrato) &&
          user.titular === true &&
          (!updatedState.valorParticular || updatedState.valorParticular === null) ? (
            <>
              <Text align="center" fontSize="0.8em" pb="20%">
                Sem horarios disponíveis para não assinantes.
              </Text>
            </>
          ) : (
            <>
              {filteredAgendasOrdemChegada?.length > 0 ? (
                <Tabs variant="enclosed" w="100%">
                  <TabList w="100%">
                    {filteredAgendas?.length > 0 ? <Tab w="50%">Hora Marcada</Tab> : <></>}
                    <Tab w="50%">Ordem de Chegada</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <TableContainer minWidth="100%">
                        <Table variant="simple" size="lg" class="table-agendamento">
                          <Tr>{mapDates}</Tr>
                        </Table>
                      </TableContainer>
                      <TableContainer minWidth="100%">
                        <Table variant="simple">
                          <Tr>{useFilteredAgendas}</Tr>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel>
                      <TableContainer minWidth="100%">
                        <Table variant="simple" size="lg" class="table-agendamento">
                          <Tr>{mapDates}</Tr>
                        </Table>
                      </TableContainer>
                      <TableContainer minWidth="100%">
                        <Table variant="simple">
                          <Tr w="100%">
                            <Td w="100%">
                              <TableContainer minWidth="100%">
                                <Table variant="simple">
                                  <Tr>{useFilteredAgendas}</Tr>
                                </Table>
                              </TableContainer>
                            </Td>
                          </Tr>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              ) : (
                <>
                  <TableContainer minWidth="100%">
                    <Table variant="simple" size="lg" class="table-agendamento">
                      <Tr>{mapDates}</Tr>
                    </Table>
                  </TableContainer>
                  <TableContainer minWidth="100%">
                    <Table variant="simple">
                      <Tr>{useFilteredAgendas}</Tr>
                    </Table>
                  </TableContainer>
                </>
              )}

              <ButtonGroup pt="1rem" pb="25%" width="100%">
                <Button
                  width="100%"
                  colorScheme="teal"
                  onClick={async () => {
                    if (
                      user.qntdeParcelas?.countParcelas &&
                      user.qntdeParcelas?.countParcelas > 0 &&
                      dataAtual >= data_parcela &&
                      !updatedState?.valorParticular
                    ) {
                      toastInadimplente(user.id_forma_pagamento, user.qntdeParcelas);
                      return;
                    }
                    if (user.loggedIn === true) {
                      if (!assinante && !updatedState.valorParticular) {
                        toast({
                          title: 'Importante',
                          description: 'Esta agenda não possui horários para não assinantes.',
                          status: 'warning',
                          duration: 4000,
                          isClosable: true,
                          position: 'bottom',
                          colorScheme: 'red',
                          containerStyle: {
                            paddingTop: '50px',
                          },
                        });
                        return;
                      }

                      if (state.orcamento_id) {
                        console.log(state.orcamento_id);
                        confirmEstimate(state.orcamento_id);
                      }

                      let states = {
                        ...escolha,
                        paciente: dependentes[pac],
                        pacienteId,
                        agenda: escolha.agenda,
                        orcamento_id: state.orcamento_id ? state.orcamento_id : null,
                      };
                      navigate('/atendimento/pedido', {
                        state: states,
                      });
                    } else {
                      if (escolha) {
                        setUser((prevState) => ({ ...prevState, pedido_pendente: { ...escolha, agenda: escolha.agenda } }));
                      }

                      disclosure.onOpen();
                    }
                  }}
                  disabled={user.loggedIn === true ? !disabled : false}
                >
                  Continuar
                </Button>
              </ButtonGroup>
            </>
          )}
        </VStack>
      </Formik>
      <BottomNavBar />
    </>
  ) : agendas?.length <= 0 && isLoading === false ? (
    <>
      <>
        {user.loggedIn === true ? <ChildHeader /> : <NoUserHeader />}
        <VStack
          w={{
            base: '100%',
          }}
          pt="15%"
          justify="center"
        >
          <div style={{ backgroundColor: '#B6C1BF', borderRadius: '50%' }}>
            <Image src={SadIcon} h="12em" />
          </div>
          <Heading textAlign="center" color="#EB8B2A" mt="2em" fontSize={24}>
            Oops...
          </Heading>
          <Heading textAlign="center" fontWeight={200} color="#585858" mt="2em" fontSize={18}>
            Estamos sem horário disponíveis<br></br>para o esse médico. Tente<br></br>novamente mais tarde
          </Heading>
        </VStack>
      </>
    </>
  ) : (
    <>
      {user.loggedIn === true ? <ChildHeader /> : <NoUserHeader />}
      <VStack
        w={{
          base: '20%',
          md: '100px',
        }}
        m="50% auto"
        justify="center"
      >
        <Loading />
      </VStack>
    </>
  );
}

export default Agendamento;
