import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  chakra,
  Button,
  Text,
  Flex,
  Heading,
  WrapItem,
  Box,
  useDisclosure,
  Spacer,
} from '@chakra-ui/react';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../AccountContext';
import BottomNavBar from '../../BottomNavBar';
import ChildHeader from '../../Dashboard/Main/ChildHeader';
import PopupPendente from './PopupPendente';
import SelectField from 'components/SelectField';
import { Form, Formik } from 'formik';
import verifyPeriodOfTheDay from '../../Utils/Functions/verifyPeriodOfTheDay';
import Loading from '../../Loading';
import haversine from 'haversine-distance';
import { SiGooglemaps } from 'react-icons/si';
import { format } from 'date-fns';
import DrawerDocumentos from './DrawerDocumentos';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Consultas() {
  const { user, setUser, page, setPage } = useContext(AccountContext);
  const navigate = useNavigate();
  const [atendimentos, setAtendimentos] = useState([]);
  const [atendimentosexame, setAtendimentosExame] = useState([]);
  const [cancelados, setCancelados] = useState([]);
  const [dependentes, setDependentes] = useState([]);
  const [pendenteAva, setPendenteAva] = useState('');
  const [pendenteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [avaliacao, setAvaliacao] = useState(true);
  const medico_sem_solicitante = 50164;
  const [documentos, setDocumentos] = useState(false);
  const [laudo_id, setLaudoId] = useState([]);
  const [prescricoes, setPrescricoes] = useState([]);
  const [documentosAssinados, setDocumentosAssinados] = useState([]);
  const formatDate = (date) => format(new Date(date), 'dd/MM/yyyy');
  const documentosDisclosure = useDisclosure();
  setPage('Agendamentos');
  const ENDPOINT_DEOVITA =
    process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_URL_DEOVITA_PROD : process.env.REACT_APP_URL_DEOVITA_TEST;
  function myToLowerCase(text) {
    if (typeof text === 'string') {
      return text.toLocaleLowerCase();
    } else {
      return text;
    }
  }

  async function getDocumentos(ambulatorio_laudo_id) {
    const response = await fetch(`${ENDPOINT_DEOVITA}/deovita/autocomplete/retrievePrescriptionAssignatureUrlsForLaudoId/${ambulatorio_laudo_id}`);
    const data = await response.json();
    return data;
  }

  const isDocumentoAssinado = (data) => {
    return data.receituario_simples_assinado || data.Atestado_assinado || data.receituario_especial_assinado;
  };

  const fetchDependentes = async () => {
    const url = ENDPOINT + '/client/dependents2';
    const titularId = {
      id_contrato: user.id_contrato,
    };
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(titularId),
    });
    const resp = await response.json();
    setDependentes(resp.data);
  };

  let assinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) assinante = true;

  const fetchAppointments = async (userID) => {
    const url = `${ENDPOINT}/appointments/${userID}`;
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const postsData = await response.json();
    console.log('p', postsData);
    setAtendimentos(postsData);

    const sortedAtendimentos = [...postsData]
      .filter(
        (atendimento) =>
          (atendimento.ambulatorio_laudo_id > 0 && atendimento.procedimento_tuss_id === 0) ||
          (atendimento.ambulatorio_laudo_id !== null && atendimento.procedimento_tuss_id === 0)
      )
      .sort((a, b) => new Date(b.APP_DATE) - new Date(a.APP_DATE));
  };

  const fetchAppointmentsExame = async (userID) => {
    const url = `${ENDPOINT}/appointments/exame/${userID}`;
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const postsData = await response.json();
    // console.log("atendimento.PROCEDURE_NAMES", postsData)
    setAtendimentosExame(postsData);
  };

  const fetchAppointmentsCancel = async (userID) => {
    const url = `${ENDPOINT}/appointments/getcancel/${userID}`;
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const postsData = await response.json();
    setCancelados(postsData);
  };

  const fetchAvaliacoesPendentes = async (cpf) => {
    const url = ENDPOINT + '/client/getAvaliacoesPendentes';
    const titularId = {
      cpf: user.cpf,
    };
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(titularId),
    });
    const resp = await response.json();
    setUser((prevState) => ({ ...prevState, avaliacao_pendente: resp }));
    setAvaliacao(resp);
  };

  const fetchSetPrescricoes = async (cpf) => {
    const url = `${ENDPOINT}/appointments/fetchPrescricoes/${cpf}`;
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const postsData = await response.json();
    const assinados = [];
    const documentosAssinados = [];

    for (const atendimento of postsData) {
      console.log(atendimento.ambulatorio_laudo_id);
      const documentos = await getDocumentos(atendimento.ambulatorio_laudo_id);
      if (isDocumentoAssinado(documentos)) {
        assinados.push(atendimento);
        documentosAssinados.push({ atendimento_id: atendimento.ambulatorio_laudo_id, documentos: documentos });
      }
    }
    setPrescricoes(assinados);
    setDocumentosAssinados(documentosAssinados);
    console.log('assinados ', assinados);
    console.log('documentos ', documentosAssinados);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchAppointments(user.username);
        await fetchAppointmentsCancel(user.username);
        await fetchAppointmentsExame(user.username);
        await fetchAvaliacoesPendentes(user.cpf);
        await fetchDependentes();
        await fetchSetPrescricoes(user.cpf);
        setLoading(true);
      } catch (error) {
        console.error('Error fetching appointments:', error);
      }
    };

    fetchData();
  }, []);

  const noShow = 'No-show';
  const confirmedByPatient = 'Confirmed by patient';
  const approved = 'Approved';
  const checkout = 'Check-out';
  const pending_payment = 'pending payment';
  const falta = 'Absence';
  const excecuting = 'Executing';
  const pending = 'Pending';

  const SelectClient = () => {
    const arrayNomeDendente = dependentes.map((dependente) => dependente.nome);
    return (
      <SelectField
        name="clients"
        label="Cliente:"
        placeholder="Cliente"
        w="100%"
        variant="outline"
        textAlign="left"
        borderColor="gray.300"
        borderRadius="10px"
        fontWeight="bold"
        onChange={(e) => {
          fetchAppointments(dependentes[e].cpf);
          fetchAppointmentsCancel(dependentes[e].cpf);
          fetchAppointmentsExame(dependentes[e].cpf);
          fetchSetPrescricoes(dependentes[e].cpf);
        }}
        options={arrayNomeDendente}
      />
    );
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const now = new Date();
  const Atendimento = (props) => {
    let atendimentosFiltrados = [];

    if (props.guia === 'agendamento') {
      atendimentosFiltrados = atendimentos.filter(
        (atendimento) =>
          atendimento.agendamento_multiplos === false &&
          (atendimento.STATUS === checkout ||
            atendimento.STATUS === confirmedByPatient ||
            atendimento.STATUS === pending_payment ||
            atendimento.STATUS === noShow ||
            atendimento.STATUS === falta)
      );

      atendimentosFiltrados = atendimentosFiltrados.concat(atendimentosexame);
    } else if (props.guia === 'historico') {
      atendimentosFiltrados = atendimentos.filter((atendimento) => {
        const appDate = new Date(atendimento.APP_DATE);
        return (
          atendimento.STATUS === approved &&
          atendimento.procedimento_tuss_id !== 0 &&
          atendimento.APP_DATE &&
          !isNaN(appDate) &&
          appDate <= now &&
          atendimento.agendamento_multiplos === false
        );
      });
    }

    return atendimentosFiltrados
      ?.sort((a, b) => new Date(b.APP_DATE) - new Date(a.APP_DATE))
      .map((atendimento) => {
        let statusAtual = '';
        let statusColor = 'black';

        if (atendimento.STATUS === approved) {
          statusColor = 'green.500';
          statusAtual = 'Concluído';
          if (
            atendimento.STATUS_AVALIACAO === false &&
            atendimento.RESOURCE_LID !== 50164 &&
            atendimento.RESOURCE_LID != null &&
            atendimento.RESOURCE_LID !== '' &&
            atendimento.RESOURCE_LID != undefined &&
            atendimento.ACTIVITY_LID !== 0 &&
            atendimento.ACTIVITY_LID !== '0'
          ) {
            setPendenteAva(atendimento);
          }
        }

        if (atendimento.STATUS === falta) {
          statusColor = 'red.500';
          statusAtual = 'Faltou';
        }

        if (atendimento.STATUS === noShow) {
          statusColor = 'green.500';
          statusAtual = 'Agendado';
        }

        if (atendimento.STATUS === checkout) {
          statusColor = 'green.500';
          statusAtual = 'Agendado';
        }
        if (atendimento.STATUS === pending_payment) {
          statusColor = 'red.500';
          statusAtual = 'Pagamento em Analise';
        }
        if (atendimento.STATUS === confirmedByPatient) {
          statusColor = 'green.500';
          statusAtual = 'Confirmado';
        }
        if (atendimento.STATUS === excecuting) {
          statusColor = 'red.500';
          statusAtual = 'Em Andamento';
        }
        if (atendimento.STATUS === pending) {
          statusColor = 'red.500';
          statusAtual = 'Pendente';
        }
        const useListProcedures = atendimento.PROCEDURE_NAMES?.map((procedure) => {
          const [procedureName, procedureStatus] = String(procedure).split(' -> ');

          if (String(procedureStatus).includes('FINALIZADO')) {
            statusAtual = 'Parcialmente Realizado';
          }
          return (
            <chakra.li>
              <Text>
                {procedureName} {procedureStatus === 'FINALIZADO' ? <Text color="teal">Realizado</Text> : null}{' '}
              </Text>
            </chakra.li>
          );
        });
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const data = atendimento.APP_DATE;
        const formData = new Date(data ? data.replace(' ', 'T') : '');
        const dataCad = atendimento.DATA_ATUALIZACAO;
        const formDataCad = new Date(dataCad ? dataCad.replace(' ', 'T') : '');

        const appTime = atendimento.START_TIME;
        const isAndroid = user.device === 'android' ? true : false;
        var mapUriPrefix = 'https://maps.apple.com/?q=';
        if (isAndroid) mapUriPrefix = 'https://maps.google.com/?q=';
        const urlMapa = `${mapUriPrefix}${atendimento.ADRESS}, ${atendimento.NUMBER}, ${atendimento.bairro}, ${atendimento.CITY}, Ceará`;

        let local = '';

        if (atendimento.atendimento_telemed) {
          local = 'Atendimento Online';
        } else {
          local = `${atendimento?.NAME?.toUpperCase()} - ${myToLowerCase(atendimento.ADRESS)}, ${myToLowerCase(atendimento.NUMBER)} - ${myToLowerCase(
            atendimento.CITY
          )}`;
        }

        return (
          <Flex
            minW="210px"
            maxW="340px"
            mx="auto"
            alignItems="center"
            overflow="hidden"
            border="1px solid"
            borderColor={statusColor}
            borderRadius="10px"
            padding="1em"
            marginTop={5}
            px={5}
            pt={5}
          >
            <VStack as={Form} alignItems="right" spacing={2.5}>
              <Heading fontSize="sm">
                {atendimento.PROCEDURE_NAMES !== null && atendimento.procedimentos_multiplos !== null
                  ? 'AGENDAMENTO DE EXAMES'
                  : atendimento.ACTIVITY_NAME
                  ? atendimento.ACTIVITY_NAME
                  : atendimento?.ESPECIALIDADE
                  ? 'CONSULTA COM ' + atendimento?.ESPECIALIDADE?.toUpperCase()
                  : 'AGENDAMENTO DE CONSULTA'} 
              </Heading>
              <Text ml="-5" fontSize="0.7em" casing="capitalize">
                {atendimento.RESOURCE_NAME === 'Sem Solicitante' ||
                atendimento.RESOURCE_NAME === null ||
                atendimento.RESOURCE_NAME === '' ||
                atendimento.RESOURCE_NAME === undefined ||
                atendimento.RESOURCE_NAME === 'SEM SOLICITANTE'
                  ? ''
                  : (atendimento.GENDER === 'F' ? 'Dra. ' : 'Dr. ') + myToLowerCase(atendimento.RESOURCE_NAME)}
              </Text>
              <chakra.p fontSize="0.7em">
                <chakra.span fontWeight="bold"> Modalidade: </chakra.span>
                {atendimento.atendimento_telemed ? 'Telemedicina' : 'Presencial'}
              </chakra.p>

              {dataCad !== null && dataCad !== 'Invalid Date' && dataCad !== undefined && (
                <chakra.p fontSize="0.7em">
                  <chakra.span fontWeight="bold"> Data do agendamento: </chakra.span>
                  {capitalizeFirstLetter(formDataCad.toLocaleDateString('pt-br', options))}
                </chakra.p>
              )}

              <chakra.p fontSize="0.7em">
                <chakra.span fontWeight="bold"> Data do atendimento: </chakra.span>
                {
                  //atendimento.turno
                  atendimento.ordem_chegada === true && atendimento.TELEMEDICINA !== true && atendimento.agenda_especialidade !== true ? (
                    <chakra fontSize="0.7em">
                      {capitalizeFirstLetter(formData.toLocaleDateString('pt-br', options)) +
                        ' no turno da ' +
                        verifyPeriodOfTheDay(appTime) +
                        ' - atendimento por ordem de chegada'}
                    </chakra>
                  ) : (
                    capitalizeFirstLetter(formData.toLocaleDateString('pt-br', options)) +
                    ' às ' +
                    (appTime ? appTime.split(':')[0] + ':' + appTime.split(':')[1] : atendimento?.DADOS_GERAIS_EXAMES[0]?.hora_selecionada_display)
                  )
                }
              </chakra.p>

              <chakra.p fontSize="0.7em">
                <Text casing="capitalize">
                  <chakra.span fontWeight="bold">PACIENTE: </chakra.span>
                  {myToLowerCase(atendimento.USER_FIRST_NAME)}
                  <chakra.span fontWeight="bold"> STATUS: </chakra.span>
                  {statusAtual}
                </Text>
              </chakra.p>
              {statusAtual !== 'Pagamento em Analise' ? (
                <>
                  <chakra.p fontSize="0.7em">
                    <chakra.span fontWeight="bold">E-Ticket: </chakra.span>
                    PMA{atendimento.APP_LID}
                  </chakra.p>
                </>
              ) : (
                <></>
              )}
              <chakra.p ml="10" fontSize="0.7em">
                <Text casing="capitalize">
                  <chakra.span fontWeight="bold"> Local: </chakra.span>
                  {local}
                </Text>
              </chakra.p>
              {atendimento.atendimento_telemed ? (
                ''
              ) : (
                <>
                  <chakra.span
                    color="rgba(14, 170, 139, 1)"
                    onClick={(event) => {
                      event.stopPropagation();
                      window.open(urlMapa, '_blank');
                    }}
                    fontSize="0.625em"
                  >
                    Clique para ver no mapa <SiGooglemaps class="googlemap" />
                    {user.coords && atendimento.latitude && atendimento.longitude
                      ? ' ' +
                        (
                          haversine(
                            { lat: user.coords.latitudeAtual, lng: user.coords.longitudeAtual },
                            { lat: atendimento.latitude, lng: atendimento.longitude }
                          ) / 1000
                        ).toFixed(2) +
                        'km'
                      : ''}
                  </chakra.span>
                </>
              )}

              {atendimento.PROCEDURE_NAMES != null && atendimento.PROCEDURE_NAMES != undefined ? (
                <>
                  <chakra.p fontSize="0.7em">
                    <chakra.span fontWeight="bold">Procedimento(s): </chakra.span>
                  </chakra.p>
                  <chakra.ul fontSize="0.7em">
                    <>{useListProcedures}</>
                  </chakra.ul>
                </>
              ) : (
                <></>
              )}
              {atendimento.tuotempo === 'INTEGRADO' || atendimento.tuotempo === null || atendimento.tuotempo === 'null' ? (
                <chakra.p fontSize="0.7em">
                  <chakra.span fontWeight="bold">
                    Agendamento realizado {atendimento.tuotempo === 'INTEGRADO' ? 'pelo aplicativo' : 'manualmente'}{' '}
                  </chakra.span>
                </chakra.p>
              ) : null}

              <Button
                mt="4"
                borderRadius="10px"
                background={user.id_contrato ? '#EB8B2A' : '#5AB2AD'}
                color="white"
                size="sm"
                maxwidth="30em"
                height="10"
                onClick={() => {
                  if (statusAtual === 'Pagamento em Analise' && atendimento.tuotempo === 'INTEGRADO') {
                    navigate('/consultas/detalhes-analise', {
                      state: {
                        atendimento: atendimento,
                        statusAtual,
                        telemed: atendimento.TELEMEDICINA,
                        financeiro_faturas_pendentes_app_id: atendimento.financeiro_faturas_pendentes_app_id,
                      },
                    });
                  } else if (statusAtual === 'Pagamento em Analise' && atendimento.tuotempo !== 'INTEGRADO') {
                    navigate('/consultas/detalhes-analise-sistema', {
                      state: {
                        atendimento: atendimento,
                        statusAtual,
                        financeiro_faturas_pendentes_app_id: atendimento.financeiro_faturas_pendentes_app_id,
                      },
                    });
                  } else {
                    navigate('/consultas/detalhes', { state: { atendimento: atendimento, statusAtual } });
                  }
                }}
              >
                ↓ Detalhes
              </Button>
            </VStack>
          </Flex>
        );
      });
  };

  const Prescricoes = () => {
    const sortedAtendimentos = [...prescricoes].sort((a, b) => new Date(b.APP_DATE) - new Date(a.APP_DATE));
    const handleDocumentoClick = async (ambulatorio_laudo_id) => {
      const documento = await getDocumentos(ambulatorio_laudo_id);
      setDocumentos(documento);
      documentosDisclosure.onOpen();
    };

    return sortedAtendimentos.map((atendimento) => {
      return (
        <Box key={atendimento.ambulatorio_laudo_id} mb={5}>
          <Flex
            key={atendimento.ambulatorio_laudo_id}
            minW="210px"
            maxW="340px"
            mx="auto"
            alignItems="center"
            overflow="hidden"
            border="1px solid"
            borderColor={'orange'}
            borderRadius="10px"
            padding="1em"
            marginTop={5}
            px={5}
            pt={5}
          >
            <VStack as={Form} alignItems="left" spacing={2.5}>
              <Heading fontSize="sm">Prescrição de exames: {formatDate(atendimento.APP_DATE)}</Heading>
              <chakra.p ml="10" fontSize="0.7em">
                <Text casing="capitalize">
                  <chakra.span fontWeight="bold"> Solicitante: </chakra.span>
                  Dr {myToLowerCase(atendimento.RESOURCE_NAME)}
                </Text>
              </chakra.p>

              {atendimento.APP_DATE ? (
                <chakra.p fontSize="0.7em">
                  <chakra.span fontWeight="bold">Data da prescrição: </chakra.span>
                  {formatDate(atendimento.APP_DATE)}
                </chakra.p>
              ) : (
                <chakra.p></chakra.p>
              )}
              <chakra.p ml="10" fontSize="0.7em">
                <Text casing="capitalize">
                  <chakra.span fontWeight="bold"> Local: </chakra.span>
                  {atendimento?.NAME?.toUpperCase()} - {myToLowerCase(atendimento.ADRESS)}, {myToLowerCase(atendimento.NUMBER)} -{' '}
                  {myToLowerCase(atendimento.CITY)}
                </Text>
              </chakra.p>
              <Spacer />
              <Button
                key={atendimento.ambulatorio_laudo_id}
                w={'20em'}
                backgroundColor={'#00BCD4'}
                fontSize={'sm'}
                color={'#FFF'}
                mb={'2em'}
                borderRadius={'0.8em'}
                onClick={() => {
                  handleDocumentoClick(atendimento.ambulatorio_laudo_id);
                }}
              >
                Solicitar PDF
              </Button>
            </VStack>
          </Flex>
        </Box>
      );
    });
  };

  const Cancelados = () => {
    return cancelados.map((atendimento) => {
      let statusColor = 'red.500';
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const dataCad = atendimento.data_agendamento;
      var formDataCad = new Date(dataCad ? dataCad.replace(' ', 'T') : '');
      formDataCad.setDate(formDataCad.getDate());
      const dataCancel = atendimento.data_cadastro;
      const formDataCancel = new Date(dataCancel ? dataCancel.replace(' ', 'T') : '');

      let local = '';

      if (atendimento.atendimento_telemed) {
        local = 'Atendimento Online';
      } else {
        local = `${atendimento?.NAME?.toUpperCase()} - ${myToLowerCase(atendimento.ADRESS)}, ${myToLowerCase(atendimento.NUMBER)} - ${myToLowerCase(
          atendimento.CITY
        )}`;
      }
      return (
        <Flex
          minW="210px"
          maxW="340px"
          mx="auto"
          alignItems="center"
          overflow="hidden"
          border="1px solid"
          borderColor={statusColor}
          borderRadius="10px"
          padding="1em"
          marginTop={5}
          px={5}
          pt={5}
        >
          <VStack as={Form} alignItems="right" spacing={2.5}>
            <Heading fontSize="sm">{atendimento.ACTIVITY_NAME}</Heading>
            <Text ml="-5" fontSize="0.7em" casing="capitalize">
              {atendimento.RESOURCE_NAME === 'Sem Solicitante' ||
              atendimento.RESOURCE_NAME === null ||
              atendimento.RESOURCE_NAME === '' ||
              atendimento.RESOURCE_NAME === undefined ||
              atendimento.RESOURCE_NAME === 'SEM SOLICITANTE '
                ? ''
                : 'Dr. ' + myToLowerCase(atendimento.RESOURCE_NAME)}
            </Text>
            <chakra.p ml="10" fontSize="0.7em">
              <Text casing="capitalize">
                <chakra.span fontWeight="bold"> Local: </chakra.span>
                {local}
              </Text>
            </chakra.p>

            {atendimento.data_agendamento ? (
              <chakra.p fontSize="0.7em">
                <chakra.span fontWeight="bold">Data do agendamento: </chakra.span>
                {formDataCad.toLocaleDateString('pt-br', options)}
              </chakra.p>
            ) : (
              <chakra.p></chakra.p>
            )}

            {atendimento.data_cadastro ? (
              <chakra.p fontSize="0.7em">
                <chakra.span fontWeight="bold">Data do cancelamento: </chakra.span>
                {formDataCancel.toLocaleDateString('pt-br', options)}
              </chakra.p>
            ) : (
              <chakra.p></chakra.p>
            )}

            <chakra.p fontSize="0.7em">
              <Text casing="capitalize">
                <chakra.span fontWeight="bold">PACIENTE: </chakra.span>
                {myToLowerCase(atendimento.USER_FIRST_NAME)}
              </Text>
            </chakra.p>

            {atendimento.APP_LID && (
              <chakra.p fontSize="0.7em">
                <chakra.span fontWeight="bold">E-Ticket: </chakra.span>
                PMA{atendimento.APP_LID}
              </chakra.p>
            )}
          </VStack>
        </Flex>
      );
    });
  };

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  return !loading ? (
    <>
      <ChildHeader />
      <Loading />
      <BottomNavBar name="consultas" />
    </>
  ) : (
    <>
      <DrawerDocumentos disclosure={documentosDisclosure} documentos={documentos} />
      <Formik initialValues={{ clients: 0 }} validateOnChange={false} validateOnBlur={false}>
        <Box as={Form}>
          <ChildHeader />
          <Tabs index={tabIndex} px={{ base: 2, sm: 1 }} py={5} w="100%" onChange={handleTabsChange}>
            {user.titular === true && user.id_contrato > 0 && <SelectClient pb="5" />}
            <TabList overflowX="auto" whiteSpace="nowrap" flex="1" height="40px" ml={'0.5em'} mt={'0.5em'}>
              <Tab
                minWidth="100px"
                color={tabIndex === 0 ? '#EB8B2A' : '#D0D0D0'}
                _selected={{ color: '#EB8B2A', fontWeight: 'bold', border: 'none' }}
                fontSize={'14px'}
                _focus={'off'}
              >
                Agendamento
              </Tab>
              <Tab
                minWidth="100px"
                fontSize={'14px'}
                color={tabIndex === 1 ? '#EB8B2A' : '#D0D0D0'}
                _selected={{ color: '#EB8B2A', fontWeight: 'bold', border: 'none' }}
                _focus={'off'}
              >
                Prescrições
              </Tab>
              <Tab
                minWidth="100px"
                fontSize={'14px'}
                color={tabIndex === 2 ? '#EB8B2A' : '#D0D0D0'}
                _selected={{ color: '#EB8B2A', fontWeight: 'bold', border: 'none' }}
                ml={'-1em'}
                _focus={'off'}
              >
                Histórico
              </Tab>
              <Tab
                minWidth="100px"
                fontSize={'14px'}
                color={tabIndex === 3 ? '#EB8B2A' : '#D0D0D0'}
                _selected={{ color: '#EB8B2A', fontWeight: 'bold', border: 'none' }}
                ml={'-1em'}
                _focus={'off'}
              >
                Cancelado
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Atendimento guia="agendamento" />
              </TabPanel>
              <TabPanel>
                <Prescricoes guia="prescricoes" />
              </TabPanel>

              <TabPanel>
                <Atendimento guia="historico" />
              </TabPanel>
              <TabPanel>
                <Cancelados guia="cancelados" />
              </TabPanel>
            </TabPanels>
          </Tabs>
          <WrapItem mt="20">
            <BottomNavBar name="consultas" />
          </WrapItem>
          {user.avaliacao_pendente === true && pendenteAva ? <PopupPendente data={pendenteAva} showPendente={pendenteModal} /> : ''}
        </Box>
      </Formik>
    </>
  );
}

export default Consultas;
