import React, { useContext, useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Center,
  Divider,
  ModalHeader,
  ModalFooter,
  Stack,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Heading,
  Image,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Illustration from '../../../../../images/illustration.svg';
import { AccountContext } from 'components/AccountContext';
import _ from 'lodash';
const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function ModalAvisoAgendamento(props) {
  const disclosure = props.disclosure;
  const [agendamento, setAgendamento] = useState([]);
  const [financeiro, setFinanceiro] = useState([]);
  const navigate = useNavigate();

  async function fetchData() {
    const url = `${ENDPOINT}/appointments/fetchDetalhesAppointment/${props?.dados?.agendamento?.agenda_exames_id}`;
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const postsData = await response.json();
    setAgendamento(postsData?.appointments ? postsData?.appointments[0] : []);
  }

  async function fetchFincanceiro() {
    const url = `${ENDPOINT}/appointments/fetchFincanceiro/${props?.dados?.agendamento?.agenda_exames_id}`;
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const postsData = await response.json();
    setFinanceiro(postsData ? postsData[0].financeiro_faturas_pendentes_app_id : []);
  }

  useEffect(() => {
    if(props?.dados?.agendamento?.agenda_exames_id){
      fetchData();
      fetchFincanceiro();
    }
  }, [disclosure.isOpen]);

  let statusAtual = '';
  const noShow = 'No-show';
  const confirmedByPatient = 'Confirmed by patient';
  const approved = 'Approved';
  const checkout = 'Check-out';
  const pending_payment = 'pending payment';
  const falta = 'Absence';
  const excecuting = 'Executing';
  const pending = 'Pending';

  return (
    <>
      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} isCentered>
        <ModalOverlay />
        <ModalContent w={'80%'} borderRadius="0.8em">
          <ModalBody>
            <Center mb={'1em'} mt={'1em'}>
              <Image src={Illustration} />
            </Center>
            <Center>
              <Text fontSize={'16px'} fontWeight={'bold'} color={'#333333'}>
                Você possui uma reserva de <br /> horário para esta semana
              </Text>
            </Center>
            <Center>
              <Text fontSize={'14px'} mt={'1em'} textAlign={'left'}>
                Entendemos que você precisa de mais uma consulta médica. Para seguir com o agendamento, você pode agendar para um data futura, pagar a
                reserva atual ou cancelar a reserva.
              </Text>
            </Center>
            <Text fontSize={'14px'} mt={'1em'}></Text>
            <Center mt={'1em'}>
              <Button
                bg={'white'}
                border={'1px solid #666666'}
                color={'#666666'}
                borderRadius={'0.8em'}
                w={'100%'}
                onClick={() => {
                  disclosure.onClose();
                }}
              >
                Agendar <br />
                outra data
              </Button>
              <Button
                bg={'#EB8B2A'}
                ml={'1em'}
                color={'white'}
                borderRadius={'0.8em'}
                w={'100%'}
                onClick={() => {
                  if (agendamento.STATUS === approved) {
                    statusAtual = 'Concluído';
                  }

                  if (agendamento.STATUS === falta) {
                    statusAtual = 'Faltou';
                  }

                  if (agendamento.STATUS === noShow) {
                    statusAtual = 'Agendado';
                  }

                  if (agendamento.STATUS === checkout) {
                    statusAtual = 'Agendado';
                  }
                  if (agendamento.STATUS === pending_payment) {
                    statusAtual = 'Pagamento em Analise';
                  }
                  if (agendamento.STATUS === confirmedByPatient) {
                    statusAtual = 'Confirmado';
                  }
                  if (agendamento.STATUS === excecuting) {
                    statusAtual = 'Em Andamento';
                  }
                  if (agendamento.STATUS === pending) {
                    statusAtual = 'Pendente';
                  }

                  navigate('/consultas/detalhes-analise', {
                    state: {
                      atendimento: agendamento,
                      statusAtual,
                      telemed: agendamento.TELEMEDICINA,
                      financeiro_faturas_pendentes_app_id: financeiro,
                      desmarcar_reserva: true,
                    },
                  });
                }}
              >
                Visualizar <br /> reserva
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalAvisoAgendamento;
