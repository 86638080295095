import React, { useContext, useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Center,
  Divider,
  ModalHeader,
  ModalFooter,
  Stack,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Heading,
  Image,
  toast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Illustration from '../../../../../images/illustration.svg';
import { AccountContext } from 'components/AccountContext';
import _ from 'lodash';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function ModalAvisoSessao(props) {
  const disclosure = props.disclosure;
  const tipo = props.tipo;
  const [valor, setValor] = useState(props.valor);
  const navigate = useNavigate();
  const { user, setPage, setUser, toastInadimplente } = useContext(AccountContext);

  const confirmEstimate = (id) => {
    if (id > 0) {
      const postData = fetch(`${ENDPOINT}/cart/estimate/${id}/confirm`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 200) {
          let newCartItems = _.filter(user.solicitacoes, (item) => !_.includes([id], item.ambulatorio_orcamento_id));
          if (newCartItems.length === 0) {
            setUser((prevState) => ({ ...prevState, solicitacoes: {} }));
          } else {
            setUser((prevState) => ({ ...prevState, solicitacoes: { ...newCartItems } }));
          }
        }
      });
    }
  };

  const [idClientClinica, setIdClientCinica] = useState(0);

  const getClinicasId = async (cpf) => {
    const url = ENDPOINT + '/clients/getClientByCpfClinica/' + cpf;
    const response = await fetch(url, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      method: 'GET',
    });
    const postsData = await response.json();
    if (postsData.length > 0) {
      setIdClientCinica(postsData[0].paciente_id);
    } else {
      let pacienteId = 0;
      if (!idClientClinica) {
        const postsData = await fetch(`${ENDPOINT}/clients/post_client_clinicas`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ cpf: user.username }),
        });

        const response = await postsData.json();
        console.log('response ', response);

        if (response.paciente_id === 0) {
          toast({
            title: 'Importante',
            description: 'Não foi possível realizar a operação. Entre em contato com o suporte',
            status: 'warning',
            duration: 4000,
            isClosable: true,
            position: 'top',
            colorScheme: 'red',
            containerStyle: {
              paddingTop: '50px',
            },
          });
          return;
        }
        pacienteId = response.paciente_id;
      }
      setIdClientCinica(pacienteId);
    }
  };

  async function fetchValorAtendimento() {
    await fetch(`${ENDPOINT}/clients/fetchValorAviso`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ agenda_exames_id: props?.dados?.agenda?.AVAILABILITY_LID }),
    })
      .then(async (res) => res.json())
      .then(async (data) => setValor(data?.valor ? data?.valor : props.valor));
  }

  useEffect(() => {
    if (props?.dados?.paciente?.cpf) {
      getClinicasId(props?.dados?.paciente?.cpf);
    }
  }, []);

  useEffect(() => {
    if (props?.dados?.agenda?.AVAILABILITY_LID) {
      fetchValorAtendimento();
    }
  }, [props?.dados?.agenda?.AVAILABILITY_LID]);

  useEffect(() => {
    setValor(valor);
  }, [valor]);

  return (
    <>
      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} isCentered>
        <ModalOverlay />
        <ModalContent m={'11%'} borderRadius="0.8em">
          <ModalBody>
            <Center mb={'1em'} mt={'1em'}>
              <Image src={Illustration} />
            </Center>
            <Center>
              <Text fontSize={'16px'} fontWeight={'bold'} color={'#333333'}>
                Você atingiu o limite de <br /> consultas esta semana
              </Text>
            </Center>
            <Center>
              <Text fontSize={'14px'} mt={'1em'} textAlign={'justify'} letterSpacing={'-0.5px'}>
                Entendemos que você precisa de mais uma consulta médica. Para seguir com o agendamento, será aplicado um valor adicional de{' '}
                {parseFloat(valor, 2)?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                . Gostaria de continuar?
              </Text>
            </Center>
            <Center mt={'1em'}>
              <Button
                bg={'white'}
                border={'1px solid #666666'}
                color={'#666666'}
                borderRadius={'0.8em'}
                onClick={() => {
                  disclosure.onClose();
                }}
              >
                Cancelar
              </Button>
              <Button
                bg={'#EB8B2A'}
                ml={'1em'}
                color={'white'}
                borderRadius={'0.8em'}
                onClick={() => {
                  const updatedDados = {
                    ...props.dados,
                    valor: valor,
                    valorExtraPsicologo: true,
                    valor_extra_psicologo: valor,
                  };
                  if (props.dados.orcamento_id) {
                    confirmEstimate(props.dados.orcamento_id);
                  }

                  if (props?.navigt_ext) {
                    setUser((prevState) => ({ ...prevState, pedido_pendente: null }));
                    navigate('/atendimento/pedido', {
                      state: {
                        ...updatedDados,
                        paciente: { nome: user.name, ...user },
                        importante: true,
                        pacienteId: idClientClinica,
                        valorExtraPsicologo: true,
                        valor_extra_psicologo: valor,
                      },
                    });
                  } else {
                    navigate('/medico-agora/especialista/conferencia', {
                      state: updatedDados,
                      teste: true,
                      pacienteId: idClientClinica,
                      valorExtraPsicologo: true,
                      valor_extra_psicologo: valor,
                    });
                  }
                }}
              >
                Continuar
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalAvisoSessao;
