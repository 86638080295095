import React, { useContext, useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Center,
  Divider,
  ModalHeader,
  ModalFooter,
  Stack,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Heading,
  Image,
  toast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from 'components/AccountContext';
import _ from 'lodash';
import ExcluirConta from '../../../images/assets/icons/warning-icon.svg';
const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function ModalDesmarcarReserva(props) {
  const disclosure = props.disclosure;
  const navigate = useNavigate()

  async function desmarcarReserva() {
    const url = `${ENDPOINT}/appointments/desmarcarReserva/${props?.agenda_exames_id}`;
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const postsData = await response.json();
    if (postsData){
      navigate('/alerts/sucesso/default');
    }else{
      toast({
        title: 'Importante',
        description: 'Ocorreu um problema ao desmarcar a reserva. Tente novamente ou entre em contato com o suporte',
        status: 'warning',
        duration: 4000,
        isClosable: true,
        position: 'bottom',
        colorScheme: 'red',
        containerStyle: {
          paddingTop: '50px',
        },
      });
    }
  }

  return (
    <>
      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} isCentered>
        <ModalOverlay />
        <ModalContent w={'80%'} borderRadius="0.8em">
          <ModalBody>
            <Center mb={'1em'} mt={'1em'}>
              <Image src={ExcluirConta} w={'2em'} />
            </Center>
            <Center>
              <Text fontSize={'16px'} fontWeight={'bold'} color={'#333333'}>
                Tem certeza que deseja desmarcar essa reserva?
              </Text>
            </Center>
            <Center>
              <Text fontSize={'14px'} mt={'1em'} textAlign={'left'}>
                Desmarcando essa reserva, o horário será liberado e será possível que outros pacientes possam agendar esse mesmo horário. Essa ação
                não terá retorno.
              </Text>
            </Center>
            <Text fontSize={'14px'} mt={'1em'}></Text>
            <Center mt={'1em'}>
              <Button
                bg={'white'}
                border={'1px solid #666666'}
                color={'#666666'}
                borderRadius={'0.8em'}
                w={'100%'}
                onClick={() => {
                  disclosure.onClose();
                }}
              >
                Cancelar
              </Button>
              <Button
                bg={'red.500'}
                ml={'1em'}
                color={'white'}
                borderRadius={'0.8em'}
                w={'100%'}
                onClick={() => {
                  desmarcarReserva();
                }}
              >
                Confirmar
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalDesmarcarReserva;
