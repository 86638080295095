import React, { useEffect, useState } from 'react';
import { VStack } from '@chakra-ui/react';
import BottomNavBar from '../../../BottomNavBar';
import MainChildHeader from '../MainChildHeader';
import { AccountContext } from '../../../AccountContext';
import { useContext } from 'react';
import Resumo from './Resumo';
import Menu from './Menu';
import ModalEmpreendedor from './ModalEmpreendedor';
import Loading from 'components/Loading';
import { useNavigate } from 'react-router-dom';
import ModalProvaDeVida from './ModalProvaDeVida';
import useRecebedor from '../../../../hooks/useRecebedor';
import ModalAvisoCadastroDadosBancarios from './ModalAvisoCadastroDadosBancarios';
import usePredefinedToastNotify from 'hooks/usePredefinedToastNotify';
import { GoAlertFill, GoClock } from "react-icons/go";


const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

const useViewModel = () => {
  const { user, setPage, setUser } = useContext(AccountContext);
  setPage('Revendedor');

  const [contratos, setContratos] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [loading, setLoading] = useState(true);

  const notify = usePredefinedToastNotify();

  const navigate = useNavigate();

  const recebedor = useRecebedor(user?.recipient_id);

  const fetchContratos = async () => {
    const url = `${ENDPOINT}/entrepreneur/extrato/contratos/${user.id_vendedor}`;
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const fetchData = await response.json();
    setUser((prevUser) => ({ ...prevUser, contratosEmpreendedor: fetchData.data.contratos }));
    setContratos(fetchData.data.contratos);
  };
  const fetchEmpresas = async () => {
    const url = `${ENDPOINT}/entrepreneur/extrato/credenciados/${user.id_vendedor}`;
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const fetchData = await response.json();
    setUser((prevUser) => ({ ...prevUser, empresasEmpreendedor: fetchData.data }));
    setEmpresas(fetchData.data);
  };
  const fetchVendedores = async () => {
    const url = `${ENDPOINT}/entrepreneur/extrato/vendedores/${user.id_vendedor}`;
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const fetchData = await response.json();
    setUser((prevUser) => ({ ...prevUser, vendedoresEmpreendedor: fetchData.data.result }));
    setVendedores(fetchData.data.result);
  };

  async function fetchDatas() {
    if (user.contratosEmpreendedor && Array.isArray(user.contratosEmpreendedor) && user.contratosEmpreendedor.length > 0) {
      setContratos(user.contratosEmpreendedor);
    } else {
      await fetchContratos();
    }
    if (user.empresasEmpreendedor && Array.isArray(user.empresasEmpreendedor) && user.empresasEmpreendedor.length > 0) {
      setEmpresas(user.empresasEmpreendedor);
    } else {
      await fetchEmpresas();
    }
    if (user.vendedoresEmpreendedor && Array.isArray(user.vendedoresEmpreendedor) && user.vendedoresEmpreendedor.length > 0) {
      setVendedores(user.vendedoresEmpreendedor);
    } else {
      await fetchVendedores();
    }
    console.log(user);
    setLoading(false);
  }
  
  const handlerOnPressGerarQrCode = () => {
    navigate('/empreendedor/qrcode');
  };

  const openChatSuporteWhatsapp = (msg='') => {
    const whatsappUrl = `https://wa.me/558002802133?text=${encodeURIComponent(msg)}`;
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        service: true,
        openInBrowser: true,
        url: whatsappUrl,
      })
    );
    window.open(whatsappUrl, '_blank');
  };

  const handlePreencherDadosBancarios = () => {
    navigate('/empreendedor/dados-bancarios');
  };

  const [showModal, setShowModal] = React.useState(true);

  const handleCancelPress = () => setShowModal(false);

  useEffect(() => {
    fetchDatas();
  }, []);

  return {
    notify,
    user,
    contratos,
    empresas,
    vendedores,
    loading,
    recebedor,
    handlerOnPressGerarQrCode,
    openChatSuporteWhatsapp,
    handlePreencherDadosBancarios,
    showModal,
    handleCancelPress,
  };

};

function Main() {
  
  const {
    user,
    contratos,
    empresas,
    vendedores,
    loading,
    recebedor,
    handlerOnPressGerarQrCode,
    openChatSuporteWhatsapp,
    handlePreencherDadosBancarios,
    showModal,
    handleCancelPress,
  } = useViewModel();

  if ( user.empreendedor === false ) {
    return <ModalEmpreendedor />;
  }

  if ( loading ) {
    return (
      <>
        <MainChildHeader />
        <Loading />
        <BottomNavBar name="empreendedor" />
      </>
    );
  }

  if (recebedor.naoSolicitouCadastrodeContaBancaria && showModal) {
    return (
      <>
        <MainChildHeader />
        <ModalAvisoCadastroDadosBancarios
          headingTitle='Cadastro de conta bancária'
          bodyText='Realize o cadastro da sua conta bancária para começar a ser um revendor.'
          icon={<GoAlertFill color='orange' size={60} style={{marginTop: '1em'}}/>}
          isOpen={showModal}
          buttonText='Cadastrar conta bancária'
          onButtonPress={handlePreencherDadosBancarios}
          cancelButtonText='Depois'
          onCancelPress={handleCancelPress}
        />
        <BottomNavBar name="empreendedor" />
      </>
    );
  }

  if (recebedor.isRecebedorRecemCadastrado && showModal) {
    return (
      <>
        <MainChildHeader />
        <ModalAvisoCadastroDadosBancarios
          headingTitle='Cadastro de conta bancária em análise'
          bodyText='Aguarde a análise de risco dos seus dados bancários.'
          icon={<GoClock color='orange' size={60} style={{marginTop: '1em'}}/>}
          isOpen={showModal}
          onCancelPress={handleCancelPress}
        />
        <BottomNavBar name="empreendedor" />
      </>
    ); 
  }

  if (recebedor.isPedidoDeAfiliacaoEmAnalise && showModal) {
    return (
      <>
        <MainChildHeader />
        <ModalAvisoCadastroDadosBancarios
          headingTitle='Cadastro de conta bancária em análise'
          bodyText='Aguarde a análise de risco dos seus dados bancários.'
          icon={<GoClock color='orange' size={60} style={{marginTop: '1em'}}/>}
          isOpen={showModal}
          onCancelPress={handleCancelPress}
        />
        <BottomNavBar name="empreendedor" />
      </>
    );
  }

  if (recebedor.isRecebedorReprovadoNaProvaDeVida && showModal) {
    return (
      <>
        <MainChildHeader />
        <ModalAvisoCadastroDadosBancarios
          headingTitle='Prova de vida recusada'
          bodyText='Seu cadastro de conta bancária foi recusado na prova de vida, contate o suporte.'
          icon={<GoAlertFill color='orange' size={60} style={{marginTop: '1em'}}/>}
          isOpen={showModal}
          buttonText='Falar com o suporte'
          onButtonPress={() => {
            const msg = 'Olá, estou com dificuldades para cadastrar minha conta bancária, fui rejeitado na Analise da Prova de Vida. Pode me ajudar?';
            openChatSuporteWhatsapp(msg);
          }}
          onCancelPress={handleCancelPress}
        />
        <BottomNavBar name="empreendedor" />
      </>
    );
  }
  
  if (recebedor.isRecebedorRejeitadoNaAnaliseDeRisco && showModal) {
    return (
      <>
        <MainChildHeader />
        <ModalAvisoCadastroDadosBancarios
          headingTitle='Cadastro de conta bancária recusado'
          bodyText='Seu cadastro de conta bancária foi recusado na análise de risco, contate o suporte.'
          icon={<GoAlertFill color='orange' size={60} style={{marginTop: '1em'}}/>}
          isOpen={showModal}
          buttonText='Falar com o suporte'
          onButtonPress={() => {
            const msg = 'Olá, estou com dificuldades para cadastrar minha conta bancária, fui rejeitado na Analise de Risco. Pode me ajudar?';
            openChatSuporteWhatsapp(msg);
          }}
          onCancelPress={handleCancelPress}
        />
        <BottomNavBar name="empreendedor" />
      </>
    );
  }

  if (recebedor.isRecebedorPrecisaFazerProvaDeVida && showModal) {
    return (
      <>
        <MainChildHeader />
        <Loading />
        <ModalProvaDeVida
          isOpen={showModal}
          onCancelPress={handleCancelPress}
        />
        <BottomNavBar name="empreendedor" />
      </>
    );
  }

  if (recebedor.isProvaDeVidaRespondidaAguardandoAnalise && showModal) {
    return (
      <>
        <MainChildHeader />
        <ModalAvisoCadastroDadosBancarios
          headingTitle='Prova de vida em análise'
          bodyText='Aguarde a análise da sua prova de vida.'
          icon={<GoClock color='orange' size={60} style={{marginTop: '1em'}}/>}
          isOpen={showModal}
          onCancelPress={handleCancelPress}
        />
        <BottomNavBar name="empreendedor" />
      </>
    );
  }

  if (recebedor.isPedidoDeAfiliacaoEmAnaliseManualDeRisco && showModal) {
    return (
      <>
        <MainChildHeader />
        <ModalAvisoCadastroDadosBancarios
          headingTitle='Cadastro de conta bancária em análise'
          bodyText='Aguarde a análise de risco dos seus dados bancários.'
          icon={<GoClock color='orange' size={60} style={{marginTop: '1em'}}/>}
          isOpen={showModal}
          onCancelPress={handleCancelPress}
        />
        <BottomNavBar name="empreendedor" />
      </>
    );
  }
  
  return (
    <>
      <MainChildHeader />
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH="10vh"
        px={3}
        mb="20"
      >
        <Resumo contratos={contratos} empresas={empresas} vendedores={vendedores} />
        <Menu contratos={contratos} empresas={empresas} vendedores={vendedores} onPressGerarQrCode={handlerOnPressGerarQrCode} />
      </VStack>
      <BottomNavBar name="empreendedor" />
    </>
  );

}

export default Main;
