import React, { useContext, useState } from 'react';
import {
  Modal,
  Text,
  Textarea,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  Heading,
  FormLabel,
  useDisclosure,
  VStack,
  Center,
} from '@chakra-ui/react';
import StarRating from './StartRating';
import { AccountContext } from '../../AccountContext';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function PopupAvaliacao(props) {
  const atendimento = props.data;
  const { user } = useContext(AccountContext);
  let dia = atendimento.APP_DATE.substring(0, 2);
  let mes = atendimento.APP_DATE.substring(3, 5);
  let ano = atendimento.APP_DATE.substring(6);

  let dataAtendimento = new Date(atendimento.APP_DATE.replace(' ', 'T'));
  let dataExtenso = dataAtendimento.toLocaleDateString('pt-br', {
    weekday: 'long',
  });
  let titulo = '';
  if (atendimento.GENDER === 'F' || atendimento.GENDER === 'f') {
    titulo = 'com a Dra';
  } else if (atendimento.GENDER === 'M' || atendimento.GENDER === 'm') {
    titulo = 'com o Dr';
  }else{
    titulo = 'com';
  }
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pendente, showPendente] = useState(true);
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [rating, setRating] = useState(0);
  const [opnion, setOpnion] = useState();
  const [loading, setLoading] = useState(false);
  const [erro, setErro] = useState(null);
  const { setUser } = useContext(AccountContext);
  async function send() {
    setLoading(true);
    const url = ENDPOINT + '/schedule/rating';
    const payload = [
      {
        agenda_exames_id: parseInt(atendimento.APP_LID),
        medico_id: parseInt(atendimento.RESOURCE_LID),
        opiniao: opnion,
        avaliacao: rating,
      },
    ];
    const options = {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(payload),
    };

    try {
      const res = await fetch(url, options);
      if (!res || !res.ok || res.status >= 400) {
        setErro('Não foi possível enviar sua avaliação');
        setLoading(false);
      } else {
        const data = (await res.json())[0];
        if (data.success) {
          setErro(null);
          setLoading(false);
          onClose();
          showPendente(false);
          setUser((prevState) => ({
            ...prevState,
            avaliacao_pendente: false,
          }));
        } else {
          setErro(data.data.error);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      setErro(error);
      console.log(error);
    }
  }

  function toCamelCase(name) {
    return name
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  return (
    <>
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={pendente} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent width="360px" height="600px" borderRadius={20}>
          <ModalHeader mt="5" fontSize="lg">
            Avalie o atendimento realizado!
          </ModalHeader>
          <ModalBody pb={6}>
            <Text size="sm">
              Consulta realizada pela {atendimento.NAME ? toCamelCase(atendimento.NAME) : atendimento.NAME} na {dataExtenso} às{' '}
              {atendimento.START_TIME ? atendimento.START_TIME.split(':')[0] + ':' + atendimento.START_TIME.split(':')[1] : ''}, {titulo}{' '}
              {atendimento.RESOURCE_NAME ? toCamelCase(atendimento.RESOURCE_NAME) : atendimento.RESOURCE_NAME}{' '}
            </Text>
            <Heading size="3" mt="5">
              Gostaríamos de saber se você ficou satisfeito com o atendimento médico.
            </Heading>
            <VStack mt="5">
              <StarRating alignItems="center" rating={rating} setRating={setRating} />
            </VStack>
            <FormControl mt={8}>
              <FormLabel>Deseja incluir sua opinião?</FormLabel>
              <Textarea placeholder="Digite Aqui" value={opnion} onChange={(e) => setOpnion(e.target.value)} />
            </FormControl>
            <Text color="red">{erro}</Text>
            <Center mt="10">
              <Button
                backgroundColor={user.id_contrato ? '#EB8B2A' : '#529C94'}
                color="#FFF"
                isFullWidth
                height="12"
                size="sm"
                borderRadius={'10'}
                isDisabled={rating <= 0}
                isLoading={loading}
                onClick={() => {
                  send();
                }}
              >
                Concluir Avaliação
              </Button>
            </Center>
            <Center>
              <Text p="5%" onClick={() => showPendente(false)}>
                Ignorar
              </Text>
            </Center>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default PopupAvaliacao;
