import { Img, VStack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import SpinSVG from '../../../../images/spin.svg';
import './loading.css';
import requestTimeout from '../../../Utils/Functions/requestTimeout';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function Loading() {
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const { state } = useLocation();
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const timeOutInSeconds = process.env.REACT_APP_REQUEST_TIMEOUT_IN_SECONDS;
  const { paciente, agenda, medico, agendamentoAvulso, procedimento, procedimentoId, paid, forma_pgto, transaction_id, pix_code, modalidade, valor } =
    state;
  const [sent, setSent] = useState(false);
  const sendWhatsapp = async () => {
    try {
      const url = ENDPOINT + '/whatsapp/agendamento';
      const payload = {
        numero: agendamentoAvulso ? onlyNumbers(paciente.fone) : onlyNumbers(paciente.celular),
        nome: paciente.nome,
        procedimento: procedimento,
        medico: medico.dados.nome,
        data: agenda.AVA_DATE,
        hora: agenda.AVA_START_TIME,
        clinica: medico.dados.empresa,
        paid: paid,
      };
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      };
      fetch(url, options);
    } catch (error) {
      console.log(error);
    }
  };

  const sendNotification = async () => {
    try {
      const url = ENDPOINT + '/notifications/sendmessagetouser';
      const payload = {
        message: { content: { title: 'DeoVita', body: 'Sua teleconsulta com Especialista foi agendada com sucesso!' } },
        cpf: paciente.cpf,
      };
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      };
      fetch(url, options);
      setSent(true);
    } catch (error) {
      console.log(error);
    }
  };
  const addAppointment = async () => {
    console.log(state);
    const url = ENDPOINT + '/appointments/';
    const payload = {
      cpf: onlyNumbers(paciente.cpf),
      clientId: paciente.paciente_id,
      scheduleId: agenda.AVAILABILITY_LID,
      procedimento_convenio_id: procedimentoId,
      paid: paid,
      pix_code: pix_code,
      forma_pagamento: forma_pgto,
      id_transacao_pagarme: transaction_id,
      triagem: modalidade === 'especialista online' ? true : false,
      modalidade: state.modalidade,
      especialidade_id: state.modalidade !== null ? state?.medico?.dados.ambulatorio_tipo_consulta_id : '',
      medico_id: state?.medico?.dados?.medico_id ? state?.medico?.dados?.medico_id : '',
    };

    try {
      const response = await fetch(url, {
        signal: requestTimeout(timeOutInSeconds).signal,
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const postsData = await response.json();
      if (postsData.success) {
        if (modalidade === 'especialista online') {
          if (sent === false) {
            sendNotification();
          }
          console.log('aqui');
        } else {
          sendWhatsapp();
        }

        if (!state.valor || state.valor === 0 || state.valor === '0.00' || state.valor === '0') {
          if (state.eventData) {
            navigate('/agendamento-calendario2', { state: { eventData: state.eventData } });
          } else {
            navigate('/alerts/sucesso/agendamento');
          }
        } else {
          navigate('/alerts/sucesso/agendamento');
        }
      } else {
        setLoad(false);
      }
    } catch (e) {
      setLoad(false);
      console.log(`Type: ${e.name}, Message: ${e.message}`);
    }
  };

  const addAppointmentPart = async () => {
    const url = ENDPOINT + '/appointments/part';
    const payload = {
      cpf: onlyNumbers(paciente.cpf),
      nome: paciente.nome,
      fone: paciente.fone,
      email: paciente.email,
      scheduleId: agenda.AVAILABILITY_LID,
      paid: paid,
      pix_code: pix_code,
      forma_pagamento: forma_pgto,
      id_transacao_pagarme: transaction_id,
    };
    try {
      const response = await fetch(url, {
        signal: requestTimeout(timeOutInSeconds).signal,
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const postsData = await response.json();
      if (postsData.success) {
        await sendWhatsapp();
        navigate('/alerts/sucesso/agendamentoAvulso');
      } else {
        setLoad(false);
      }
    } catch (e) {
      setLoad(false);
      console.log(`Type: ${e.name}, Message: ${e.message}`);
    }
  };
  useEffect(() => {
    if (load) agendamentoAvulso ? addAppointmentPart() : addAppointment();
  }, []);

  function Spin() {
    return (
      <VStack w={{ base: '90%', md: '500px' }} m="50% auto" justify="center">
        <Img size="sm" margin="auto" src={SpinSVG} className="rotate" />
      </VStack>
    );
  }

  return load ? Spin() : navigate('/alerts/erro/agendamento');
}

export default Loading;
