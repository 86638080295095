import {
  Button,
  chakra,
  VStack,
  ButtonGroup,
  Container,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Heading,
  Stack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BottomNavBar from '../../../BottomNavBar';
import ChildHeader from '../ChildHeader';
import Loading from '../../../Loading';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

function ConferePedido() {
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const navigate = useNavigate();
  const { state } = useLocation();
  const arrName = state.medicoNome.split(' ');
  const [splitRules, setSplitRules] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { agenda } = state;
  const [pickWarning, setPickWarning] = useState(false);

  // mandar cpf no lugar do paciente_id
  const lockAgenda = async (agendaId) => {
    try {
      const url = ENDPOINT + '/schedule/lockByAgendaExames/' + agendaId + '/' + state.paciente.cpf;
      const response = await fetch(url, {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        method: 'GET',
      });
      const postsData = await response.json();
    } catch (error) {
      console.log(error);
    }
  };
  const checkAvailability = async (agendaId) => {
    const url = ENDPOINT + '/schedule/checkAvailability/' + agendaId + '/' + state.pacienteId;

    const response = await fetch(url, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      method: 'GET',
    });

    const postsData = await response.json().then(({ available }) => {
      if (available === true) {
        lockAgenda(state.agenda.AVAILABILITY_LID);
        if (!splitRules) {
          if (!state.valor || state.valor === 0 || state.valor === '0.00' || state.valor === '0') {
            navigate('/atendimento/loading/', {
              state: {
                valor: 0,
                paciente: state.paciente,
                agenda: state.agenda,
                medico: state.medico,
                orientacoes: state.orientacoes,
                confim: true,
                transaction_id: 9999999,
                procedimentoId: state.procedimentoId,
                procedimento: state.procedimento,
                product_name: state.agenda.ACTIVITY_NAME,
                paid: true,
                forma_pgto: 'DINHEIRO',
                valorExtraPsicologo: state?.valorExtraPsicologo,
                valor_extra_psicologo: state?.valor_extra_psicologo,
              },
            });
          } else {
            navigate('/pagamentos', {
              state: {
                parcelsActive: true,
                valor: state.valor,
                expiration_pix_in_min: 5,
                service: 'schedule',
                navig_to: '/alerts/sucesso/agendamento/',
                item: {
                  cpf: onlyNumbers(state.paciente.cpf),
                  nome: state.paciente.nome,
                  fone: state.paciente.celular ? state.paciente.celular : state.paciente.phone1,
                  email: state.paciente.email,
                  scheduleId: state.agenda.AVAILABILITY_LID,
                  procedimento_convenio_id: state.procedimentoId,
                  product_name: state.agenda.ACTIVITY_NAME,
                  confim: true,
                  orcamento_id: state.orcamento_id ? state.orcamento_id : null,
                  valorExtraPsicologo: state?.valorExtraPsicologo,
                  valor_extra_psicologo: state?.valor_extra_psicologo,
                },
              },
            });
          }
        } else {
          navigate('/pagamentos', {
            state: {
              parcelsActive: true,
              valor: state.valor,
              split: splitRules.split_rules,
              expiration_pix_in_min: 5,
              service: 'schedule',
              navig_to: '/alerts/sucesso/agendamento/',
              item: {
                cpf: onlyNumbers(state.paciente.cpf),
                nome: state.paciente.nome,
                fone: state.paciente.celular ? state.paciente.celular : state.paciente.phone1,
                email: state.paciente.email,
                scheduleId: state.agenda.AVAILABILITY_LID,
                procedimento_convenio_id: state.procedimentoId,
                product_name: state.agenda.ACTIVITY_NAME,
                confim: true,
                orcamento_id: state.orcamento_id ? state.orcamento_id : null,
                valorExtraPsicologo: state?.valorExtraPsicologo,
                valor_extra_psicologo: state?.valor_extra_psicologo,
              },
            },
          });
        }
      } else {
        setPickWarning(true);
      }
    });
  };

  async function getSplitRules(agendaId) {
    try {
      const url = ENDPOINT + '/schedule/splitRules/' + agendaId + '/' + state.procedimentoId + '/';
      const options = {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      };
      const response = await fetch(url, options);
      const splitRules = await response.json().then(async (result) => {
        result.split_rules.forEach((rule) => {
          if (rule.option && typeof rule.option === 'string') {
            rule.option = JSON.parse(rule.option);
          }
        });
        console.log(result);
        setSplitRules(result);
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(true);
      setLoading(false);
    }
  }

  useEffect(() => {
    if ((agenda?.split_pagamento || agenda?.split_pagamento_empresa) && state.valor !== 0 && state.valor !== '0.00' && state.valor !== '0') {
      getSplitRules(agenda.AVAILABILITY_LID);
    } else {
      setLoading(false);
    }
  }, []);
  return loading ? (
    <Loading />
  ) : (
    <>
      <ChildHeader />
      <Modal isOpen={pickWarning} isCentered>
        <ModalOverlay />
        <ModalContent height="250px" width="300px" border-radius="1em">
          <ModalHeader></ModalHeader>

          <ModalBody>
            <VStack>
              <Heading size="md" textAlign="center" pl="5" pr="5" mt="15">
                Opa! O horário escolhido não está mais disponível.
              </Heading>
              <Stack direction="row" ml="-4" pt="1em" spacing={5} align="center">
                <Button
                  id="id_cpd_continuar"
                  backgroundColor={'#EB8B2A'}
                  color="#FFF"
                  variant="solid"
                  size="lg"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Continuar
                </Button>
              </Stack>
            </VStack>
          </ModalBody>

          <VStack ml="4"></VStack>
        </ModalContent>
      </Modal>
      <VStack
        w={{
          base: '90%',
          md: '500px',
        }}
        m="auto"
        alignItems="left"
        spacing={1}
        marginBottom="10%"
      >
        <chakra.h1 fontWeight="bold" margin="5% 0" fontSize="xl">
          Informações do Pedido:
        </chakra.h1>

        <chakra.p fontWeight="bold">
          {state.agenda.ACTIVITY_NAME ? state.agenda.ACTIVITY_NAME.toUpperCase() + ' COM ' : ''}
          {arrName[0].toUpperCase()} {arrName[1]?.toUpperCase()}
        </chakra.p>
        <chakra.p color="gray.500">Paciente:</chakra.p>
        <chakra.p fontWeight="bold">{state.paciente.nome.toUpperCase()}</chakra.p>
        <chakra.p color="gray.500">Procedimento:</chakra.p>
        <chakra.p fontWeight="bold">{state.procedimento}</chakra.p>
        <chakra.p color="gray.500">Data:</chakra.p>
        <chakra.p fontWeight="bold">
          {state.agenda.AVA_DATE}
          {' as '}
          {state.agenda.AVA_START_TIME}
        </chakra.p>
        <chakra.p color="gray.500">Local:</chakra.p>

        {state.medico.dados.agenda_especialidade ? (
          <chakra.p fontWeight="bold">Atendimento Online</chakra.p>
        ) : (
          <>
            <chakra.p fontWeight="bold">{state.clinica}</chakra.p>
            <chakra.p color="black.500" fontSize="xs">
              {' '}
              {state.logradouro}, {state.numero} - {state.bairro} - {state.municipio}
            </chakra.p>
          </>
        )}
        <chakra.p color="gray.500">Valor:</chakra.p>
        <chakra.p fontWeight="bold">R$ {state.valor}</chakra.p>
        <Container maxW="2xl" leftContent>
          <Box padding="1" borderRadius="md" borderWidth="1px" margin="5% 0" bcolor="black" maxW="sm">
            <chakra.p color="black">Orientações:</chakra.p>
            <chakra.p color="red.500" margin="1% 0" fontSize="sm">
              {!state.valor || state.valor === 0 || state.valor === '0.00' || state.valor === '0'
                ? 'Certifique-se de entrar no app 15 minutos antes da sua consulta medica iniciar'
                : 'Ao prosseguir com seu agendamento, este horário será reservado ao paciente por 30 minutos e será liberado imediatamente após, caso não haja confirmação de pagamento.'}
            </chakra.p>
            <chakra.p color="red.500" margin="5% 0" fontSize="sm">
              {state.agenda.preparo}
            </chakra.p>
          </Box>
        </Container>

        <ButtonGroup pt="1rem" pb="15%" width="100%">
          <Button
            colorScheme="teal"
            width="100%"
            onClick={() => {
              checkAvailability(state.agenda.AVAILABILITY_LID);
            }}
          >
            Continuar
          </Button>
        </ButtonGroup>
      </VStack>
      <BottomNavBar />
    </>
  );
}

export default ConferePedido;
