import React, { useContext } from 'react';
import {chakra, Center, Heading, Modal, ModalBody, ModalContent, ModalOverlay, VStack, Button } from '@chakra-ui/react';

import { GoAlertFill } from "react-icons/go";
import usePredefinedToastNotify from 'hooks/usePredefinedToastNotify';
import { AccountContext } from 'components/AccountContext';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

const Props = {
  isOpen: true,
  onCancelPress: ()=>{},
};

const ModalProvaDeVida = (props = Props) => {

  const { user } = useContext(AccountContext);

  const notify = usePredefinedToastNotify();

  const fetchProvaDeVida = async (recipient_id='') => {
    try {

      if (!recipient_id.length) {
        throw new Error('Recipient ID not found');
      }

      const url = `${ENDPOINT}/entrepreneur/prova-de-vida/${recipient_id}`;

      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
      });

      const res = await response.json();
      
      console.log('res link prova de vida', res);

      if (!res?.url) {
        throw 'Falha ao buscar prova de vida';
      }

      return res.url;
      
    } catch (error) {
      console.error('error', error);
      notify.error('Falha ao buscar prova de vida', 'Contate o suporte para mais informações');
    }
  };

  const onConfirmPress = async () => {
    try {

      if (!user?.recipient_id) {
        throw new Error('Recipient ID not found');
      }

      const url = await fetchProvaDeVida(user?.recipient_id);

      if (!url) {
        throw new Error('Prova de vida não encontrada');
      }
      
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          service: true,
          openInBrowser: true,
          url,
        })
      );

    } catch (error) {
      console.error('error', error);
      notify.error('Falha ao buscar prova de vida', 'Contate o suporte para mais informações');
    }
  };

  return (
    <Modal isOpen={props.isOpen} isCentered size="xl">
      <ModalOverlay />
      <ModalContent m={'5%'} borderRadius="0.8em">
        <ModalBody>
          <Center>
            <GoAlertFill color='orange' size={60} style={{marginTop: '1em'}}/>
          </Center>
          <Center>
            <Heading textAlign="center" color="#333333" mt="5" size="md" mb={'1em'}>
              Prova de vida
            </Heading>
          </Center>
          <VStack bg={'#E5E5E5'} p={'1'} borderRadius={'0.8em'}>
            <chakra.h3 mt="0.16em" color="#333333" textAlign="center" fontSize="1em">
              Realize a prova de vida para concluir o seu cadastro de revendedor.
            </chakra.h3>
          </VStack>
          <Center mt={'1em'} mb={'1em'}>
            <Button
              bg={'orange'}
              color={'white'}
              h={'2.5em'}
              borderRadius={'0.8em'}
              onClick={onConfirmPress}
            >
              Fazer prova de vida
            </Button>
          </Center>
          <Center mt={'1em'} mb={'1em'}>
            <Button
              bg={'white'}
              color={'orange'}
              h={'2.5em'}
              borderRadius={'0.8em'}
              onClick={props.onCancelPress}
            >
              Depois
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalProvaDeVida;