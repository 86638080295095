import React, { useContext, useState } from 'react';
import {
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Menu,
  MenuButton,
  Radio,
  RadioGroup,
  useDisclosure,
  VStack,
  chakra,
  Button,
  Input,
  HStack,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { useField } from 'formik';
import { ChevronDownIcon, Search2Icon } from '@chakra-ui/icons';
import { AccountContext } from './AccountContext';

const SelectField = ({ label, ...props }) => {
  
  const { options, placeholder, onChange } = props;
  const [field, meta, helpers] = useField(props);
  const { value } = meta;
  const [textSearch, setTextSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const { setValue } = helpers;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const colorErro = meta.error ? { colorScheme: 'red' } : {};
  const [searchResults, setSearchResults] = useState(options);
  const { user } = useContext(AccountContext);
  let textButton;
  if (typeof value === 'number') {
    if (typeof options[value] === 'string') {
      textButton = options[value].substring(0, 25) + (options[value].length > 25 ? '...' : '');
    } else {
      textButton = '';
    }
  } else if (placeholder) {
    textButton = placeholder;
  } else {
    textButton = 'Selecionar';
  }
  const search = async () => {
    setLoading(true);
    const filteredArr = options.filter((word) =>
      word
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .includes(
          textSearch
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
        )
    );

    setSearchResults(filteredArr);
    setLoading(false);
  };

  //resolver aviso de erro lançado quando clicar no select

  let usuarioAssinante = user.id_contrato && user.descricao_plano ? true : false;
  if (user.titular === false) usuarioAssinante = true;
  return (
    <>
      <FormControl isInvalid={meta.error}>
        <FormLabel>{label}</FormLabel>
        <Menu>
          <Center>
            <MenuButton as={Button} onClick={onOpen} rightIcon={<ChevronDownIcon />} {...field} {...props} {...colorErro}>
              {textButton}
            </MenuButton>
          </Center>
        </Menu>
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </FormControl>
      <Drawer isOpen={isOpen} onClose={onClose} finalFocusRef={btnRef} placement="bottom">
        <DrawerOverlay />

        <DrawerContent borderRadius={20} mb="-4" width="90" h="80%">
          <DrawerHeader mt="5">Selecione</DrawerHeader>
          <DrawerBody>
            <HStack>
              <InputGroup>
                <Input
                  placeholder="Buscar"
                  focusBorderColor="teal"
                  backgroundColor="white"
                  shadow="lg"
                  borderRadius="50px"
                  value={textSearch}
                  onChange={(event) => setTextSearch(event.target.value)}
                  onKeyUp={(event) => {
                    search();
                  }}
                />

                <InputRightElement width="15%">
                  <Button
                    background={usuarioAssinante ? '#EB8B2A' : '#529C94'}
                    borderRadius="50px"
                    width="100%"
                    children={<Search2Icon color="white" />}
                    onClick={search}
                    isLoading={loading}
                  ></Button>{' '}
                </InputRightElement>
              </InputGroup>
            </HStack>
            <RadioGroup
              name="select"
              w="100%"
              value={value}
              pb={5}
              onChange={(e) => {
                setValue(options.indexOf(searchResults[e]));
                onChange(e);
                onClose();
              }}
            >
              <VStack align="start" w="100" spacing={1} mb="100%">
                {searchResults.map((option, index) => {
                  return (
                    <Radio value={index} padding="1em" autofocus="true">
                      <chakra.p fontWeight="bold" fontSize="sm">
                        {option}
                      </chakra.p>
                    </Radio>
                  );
                })}
              </VStack>
            </RadioGroup>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SelectField;
