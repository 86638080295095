import {
  Drawer,
  DrawerBody,
  DrawerContent,
  Text,
  DrawerOverlay,
  Center,
  Button,
  DrawerCloseButton,
  DrawerHeader,
  Flex,
  Image,
  FormControl,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react';
import { AccountContext } from 'components/AccountContext';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalVoucherIcon from '../../images/modal-voucher-icon.svg';

const ENDPOINT =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BASE_URL_API_SERVER_PROD
    : process.env.REACT_APP_BASE_URL_API_SERVER_TEST;

const validationSchema = Yup.object({
  cod_indicacao: Yup.string().trim().required('Digite o código para continuar'),
});

function DrawerIndicacao(props) {
  const disclosure = props.disclosure;
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { user, setUser } = useContext(AccountContext);
  var { state } = useLocation();

  if (user.loggedIn) {
    state = user;
  }

  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  function handleError() {
    setError(true);
    setLoading(false);
  }

  const sessionLogin = () => {
    fetch(`${ENDPOINT}/auth/login`, {
      credentials: 'include',
    })
      .catch((err) => {
        setUser((user) => {
          return { loggedIn: false, device: user.device };
        });
        return;
      })
      .then((r) => {
        if (!r || !r.ok || r.status >= 400) {
          setUser((user) => {
            return { loggedIn: false, device: user.device };
          });
          return;
        }
        return r.json();
      })
      .then((data) => {
        if (!data) {
          setUser((user) => {
            return { loggedIn: false, device: user.device };
          });
          return;
        }
        setUser({ ...data, device: user.device });
        if (data.id_contrato === 0 && data.titular === true) {
          if (data.contrato_pendente_pagamento === true) {
            navigate('/cadastro/analise', {
              state: {
                client_id: data.id_cliente_deovita,
                name: data.name,
                cpf: data.cpf,
              },
            });
          } else {
            navigate('/dashboard');
          }
        } else {
          navigate('/dashboard');
        }
      });
  };
  const userLogin = () => {
    const vals = {
      username: onlyNumbers(state.cpf),
      password: state.senha,
    };
    fetch(`${ENDPOINT}/auth/login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(vals),
    })
      .catch((err) => {
        handleError();
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          handleError();
          return;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        setUser((prevState) => ({ ...prevState, ...data }));
        if (data.status) {
          handleError();
        } else if (data.loggedIn) {
          const payload = {
            cpf: data.cpf,
            numero: data.phone1,
            nome: data.name,
          };
          navigate('/dashboard');
        }
      });
  };
  return (
    <>
      <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="bottom">
        <DrawerOverlay />
        <DrawerContent borderTopRadius={20}>
          <DrawerCloseButton />
          <DrawerHeader fontSize={12} color={'#9E9E9E'}>
            Voucher
          </DrawerHeader>
          <DrawerBody borderTop={'2px solid #F5F5F5'} mb={8}>
            <Center flexDirection={'column'} gap={4} my={4} mb={8}>
              <Image src={ModalVoucherIcon} height={'4rem'} />
              <Text fontSize={20} fontWeight={800} color={'#616161'}>
                Você possui um voucher?
              </Text>
              <Text color={'#616161'} textAlign={'center'}>
                Se você está realizando sua assinatura e recebeu um voucher, preencha o campo abaixo com o código.
              </Text>
            </Center>
            <Formik
              validationSchema={validationSchema}
              initialValues={{ cod_indicacao: '' }}
              onSubmit={(values, actions) => {
                setErrorText('');
                const vals = { ...values };
                if (vals.cod_indicacao.toUpperCase().includes('COR')) {
                  fetch(`${ENDPOINT}/voucher/${vals.cod_indicacao}/${String(state.name).replace(' ', '%20')}/${state.cpf}/${props.clientId}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                  }).then((res) => {
                    if (!res || !res.ok || res.status >= 400) {
                      setErrorText('Falha na verificação do código de indicação');
                      actions.setSubmitting(false);
                      return;
                    }
                    res.json().then((data) => {
                      if (!data || data.success === false) {
                        setErrorText('Código de indicação inválido');
                        actions.setSubmitting(false);
                        return;
                      }
                      if (data.success === true && data.message) {
                        setUser((prevState) => ({
                          ...prevState,
                          voucher: data && data.message && data.message.codigo,
                        }));
                        if (user.loggedIn === true) {
                          sessionLogin();
                        } else {
                          userLogin();
                        }
                      }
                    });
                  });
                } else if (vals.cod_indicacao.toUpperCase() === 'BLACKDEOVITA50' && new Date() < new Date('2024-11-30')) {
                  // fetch(`${ENDPOINT}/voucher/${vals.cod_indicacao}/${String(state.name).replace(' ', '%20')}/${state.cpf}/${props.clientId}`, {
                  //   method: 'GET',
                  //   credentials: 'include',
                  //   headers: { 'Content-Type': 'application/json' },
                  // }).then((res) => {
                  //   if (!res || !res.ok || res.status >= 400) {
                  //     setErrorText('Falha na verificação do código de indicação');
                  //     actions.setSubmitting(false);
                  //     return;
                  //   }
                  //   res.json().then((data) => {
                  //     if (!data || data.success === false) {
                  //       setErrorText('Código de indicação inválido');
                  //       actions.setSubmitting(false);
                  //       return;
                  //     }
                  //     if (data.success === true && data.message) {
                  // setUser((prevState) => ({
                  //   ...prevState,
                  //   voucher: {
                  //     vendedor_id: vals.cod_indicacao.toUpperCase(),
                  //     recipient_id_gerente: null,
                  //     recipient_id_diretor: null,
                  //     recipient_id_pagarme: null,
                  //   }, //data && data.message && data.message.codigo,
                  // }));

                  navigate('/cadastro/plano-black');

                  // console.log('loggedIn', user.loggedIn);
                  
                  // if (user.loggedIn === true) {
                  //   sessionLogin();
                  // } else {
                  //   userLogin();
                  // }
                  //     }
                  //   });
                  // });
                } else {
                  fetch(`${ENDPOINT}/entrepreneur/voucher`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(vals),
                  }).then((res) => {
                    if (!res || !res.ok || res.status >= 400) {
                      setErrorText('Falha na verificação do código de indicação');
                      actions.setSubmitting(false);
                      return;
                    }
                    res.json().then((data) => {
                      if (!data || data.success === false) {
                        setErrorText('Código de indicação inválido');
                        actions.setSubmitting(false);
                        return;
                      }
                      if (data.success === true && data.data && data.data.length > 0) {
                        setUser((prevState) => ({
                          ...prevState,
                          voucher: data.data[0],
                        }));

                        setDisabled(true);
                        disclosure.onClose();
                      }
                    });
                  });
                }

                actions.setSubmitting(false);
              }}
            >
              {({ isSubmitting, isValid, dirty }) => {
                return (
                  <Flex flexDirection={'column'} gap={4} as={Form}>
                    <Field name="cod_indicacao">
                      {({ field, form }) => (
                        <FormControl isRequired isInvalid={form.errors.cod_indicacao && form.touched.cod_indicacao}>
                          {/* <FormLabel>Titular da conta</FormLabel> */}
                          <Input {...field} placeholder="Digite o código" disabled={disabled} borderRadius={'xl'} />
                          <FormErrorMessage>{form.errors.cod_indicacao}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Button
                      borderRadius={'full'}
                      colorScheme="teal"
                      type="submit"
                      isLoading={isSubmitting}
                      disabled={!(isValid && dirty && !isSubmitting)}
                    >
                      {!disabled ? 'Aplicar Voucher' : 'aplicado'}
                    </Button>
                  </Flex>
                );
              }}
            </Formik>
            {errorText && (
              <Text color="red" mt={4} textAlign={'center'}>
                {errorText}
              </Text>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DrawerIndicacao;
